import React, { useCallback, useMemo } from "react";
import { Typography, TextField, Grid, Tooltip } from "@mui/material";
import { formatData } from "../Table/utils";
import { useDispatch, useSelector } from "react-redux";
import { Inventory2Outlined } from "@mui/icons-material";
import { configureLabel } from "../Table/utils";
const cardHeaders = new Set(["header", "toolTip", "styling", "valueHeader"]);

const CustomKpiCard = (props) => {
  const { data, values } = props;
  const { dependentInfo, clientConfig } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  const [oldCur, newCur] = useMemo(() => {
    return clientConfig?.[0]?.currency?.split(",") || ["", ""];
  }, [clientConfig]);

  let cardInfo = { ...data };
  let entries = Object.entries(cardInfo);

  if (cardInfo.hasOwnProperty("action")) {
    delete cardInfo.action;
    entries = Object.entries(cardInfo);
  }
  const onAction = (obj) => {
    if (data.hasOwnProperty("action")) {
      switch (data.action.actionName) {
        case "DEPENDENT":
          let params = {};
          params[`${data.action.key}`] = dependentInfo[`${data.action.key}`]
            ? !dependentInfo[`${data.action.key}`]
            : true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          break;

        default:
          break;
      }
    }
  };

  const formatByPrefix = useCallback(
    (type, str) => {
      const fChar = str.charAt(0);
      if (fChar === "$" || fChar === "€" || fChar === "¥") {
        return formatData(type, {
          value: parseInt(str.replace(/,|\$|€|¥/g, "")),
          currency: newCur,
        });
      }
      return str;
    },
    [newCur, formatData]
  );
  return (
    <div className="KPICards" style={{ padding: 10, height: values?.height }}>
      <Tooltip title={cardInfo?.toolTip}>
        <Typography
          style={{
            marginBottom: 5,
            fontSize: "1.4rem",
            fontWeight: 500,
            overflow: "hidden",
            height: "3rem",
            ...(cardInfo?.styling?.header || values?.styling?.header),
          }}
        >
          {values?.headerIcon ? (
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Inventory2Outlined />
              {data.header}
            </div>
          ) : (
            data.header
          )}
        </Typography>
      </Tooltip>

      <Typography
        style={{
          fontSize: "0.9rem",
          fontWeight: 500,
          overflow: "hidden",
          textAlign: "right",
        }}
      >
        {data.valueHeader}
      </Typography>
      {data?.designType ? (
        <>
          <Grid container>
            {data.info.map((infoObj) => (
              <>
                {infoObj?.label ? (
                  <>
                    <Grid item md={6} lg={6} xl={6}>
                      {infoObj.label} <br />
                      <Typography
                        style={{ fontSize: "0.75rem", paddingBottom: "5px" }}
                        color="#0055AF"
                        fontWeight="600"
                      >
                        {infoObj.value}
                      </Typography>
                      <div className="spacer"></div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item md={6} lg={6} xl={6}></Grid>
                  </>
                )}
              </>
            ))}
          </Grid>
        </>
      ) : (
        <>
          {entries.map((obj) => (
            <>
              {!cardHeaders.has(obj[0]) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    verticalAlign: "center",
                    paddingTop: 5,
                    paddingBottom: 5,
                    ...(cardInfo?.styling?.entry || values?.styling?.entry),
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      ...(cardInfo?.styling?.label || values?.styling?.label),
                    }}
                  >
                    {obj[0] && configureLabel(obj[0], oldCur, newCur)}
                  </Typography>
                  {obj[0] === "TY Plan" && props.values.editableField ? (
                    <TextField
                      id="outlined-number"
                      size="small"
                      defaultValue={obj[1]}
                      inputProps={{
                        style: {
                          height: "10px",
                          width: 80,
                          color: "#0055AF",
                          fontSize: "1rem",
                          fontWeight: "600",
                          textAlign: "right",
                          paddingRight: 3,
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      style={{
                        fontSize: "1rem",
                        ...(cardInfo?.styling?.value || values?.styling?.value),
                      }}
                      color="#0055AF"
                      fontWeight="600"
                      onClick={() => {
                        onAction(obj);
                      }}
                    >
                      {values?.format
                        ? formatByPrefix(values.format, obj[1])
                        : obj[1] && configureLabel(obj[1], oldCur, newCur)}
                    </Typography>
                  )}
                </div>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default CustomKpiCard;
