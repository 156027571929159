import React from "react";
import { Grid, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SelectRenderer from "../../components/Select/SelectRenderer";
import CustomDatePicker from "../../components/CustomDatePicker";
import CheckboxGroup from "../../components/CheckboxGroup";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) =>
	createStyles({
		smallRadioButton: {
			"& svg": {
				width: "12px",
				height: "12px",
			},
		},
	}),
);

export const StrategyConfig = (props) => {
	const {data}=props
	console.log("data",data)
	// const {pathOnCancel,pathOnSave}=data
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<React.Fragment>
			<div style={{ padding: "2px 20px" }}>
				<div
					style={{
						fontWeight: "600",
						fontStyle: "poppins",
						fontSize: "15px",
						marginBottom: "5px",
					}}
				>
					Set Strategy
				</div>

				<div style={{ fontStyle: "poppins", fontSize: "13px" }}>
					In Season Strategy
				</div>
				<div style={{ padding: "5px 30px" }}>
					<FormGroup style={{ fontStyle: "poppins", fontSize: "13px" }}>
						<FormControlLabel
							control={<Checkbox size="small" defaultChecked />}
							label="Auto apply to new products"
						/>
					</FormGroup>
					<Grid
						container
						sx={{ padding: "20px", paddingBottom: "2px" }}
						justifyContent="center"
					>
						<Grid item xs={6} sm={6} md={6}>
							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Demand type
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										APS
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										WOS
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Store Inv push %
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										End of life
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ width: "50px" }}>
										<CustomDatePicker
											label={null}
											labeldirection={"column"}
											values={null}
											isMandatory={false}
											onChange={(value) => {}}
										/>
									</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={6} md={6} sx={{ marginBottom: "15px" }}>
							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Target Destination
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<FormControlLabel
										control={<Checkbox size="small" defaultChecked />}
										label="DC"
									/>
									<FormControlLabel
										control={<Checkbox size="small" defaultChecked />}
										label="Store"
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Execution date
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ width: "50px" }}>
										<CustomDatePicker
											label={null}
											labeldirection={"column"}
											values={null}
											isMandatory={false}
											onChange={(value) => {}}
										/>
									</div>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Label
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Frequency
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Allocation trigger
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ display: "flex" }}>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
											className={classes.smallRadioButton}
										>
											<FormControlLabel
												className={classes.smallRadioButton}
												value="WOS"
												control={<Radio />}
												label="WOS"
											/>
										</RadioGroup>

										<input
											type="number"
											style={{
												width: "90px",
												height: "30px",
												margin: "0px 10px",
												marginTop: "7px",
											}}
										/>

										<RadioGroup
											className={classes.smallRadioButton}
											sx={{ marginLeft: "12px" }}
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
										>
											<FormControlLabel
												className={classes.smallRadioButton}
												value="%ofWOS"
												control={<Radio />}
												label="%ofWOS"
											/>
										</RadioGroup>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
			<Divider light={true} variant="middle"></Divider>

			<div style={{ padding: "2px 20px" }}>
				<div style={{ fontStyle: "poppins", fontSize: "13px" }}>
					Clearance Strategy
				</div>
				<div style={{ padding: "5px 30px" }}>
					<FormGroup style={{ fontStyle: "poppins", fontSize: "13px" }}>
						<FormControlLabel
							control={<Checkbox size="small" defaultChecked />}
							label="Auto apply to new products"
						/>
					</FormGroup>
					<Grid
						container
						sx={{ padding: "20px", paddingBottom: "2px" }}
						justifyContent="center"
					>
						<Grid item xs={6} sm={6} md={6}>
							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Demand type
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										APS
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										WOS
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Store Inv push %
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										End of life
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ width: "50px" }}>
										<CustomDatePicker
											label={null}
											labeldirection={"column"}
											values={null}
											isMandatory={false}
											onChange={(value) => {}}
										/>
									</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={6} md={6} sx={{ marginBottom: "15px" }}>
							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Target Destination
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<FormControlLabel
										control={<Checkbox size="small" defaultChecked />}
										label="DC"
									/>
									<FormControlLabel
										control={<Checkbox size="small" defaultChecked />}
										label="Store"
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Execution date
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ width: "50px" }}>
										<CustomDatePicker
											label={null}
											labeldirection={"column"}
											values={null}
											isMandatory={false}
											onChange={(value) => {}}
										/>
									</div>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Label
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Frequency
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<SelectRenderer
										options={[]}
										isMandatory={false}
										isMulti={false}
										width="18rem"
										filterLabel={[]}
										updateSelected={() => {
											return null;
										}}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ marginBottom: "15px" }}>
								<Grid item xs={4} sm={4} md={4}>
									<span
										style={{
											fontStyle: "poppins",
											fontSize: "11px",
											color: "gray",
										}}
									>
										Allocation trigger
									</span>
								</Grid>
								<Grid item xs={8} sm={8} md={8}>
									<div style={{ display: "flex" }}>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
											className={classes.smallRadioButton}
										>
											<FormControlLabel
												className={classes.smallRadioButton}
												value="WOS"
												control={<Radio />}
												label="WOS"
											/>
										</RadioGroup>

										<input
											type="number"
											style={{
												width: "90px",
												height: "30px",
												margin: "0px 10px",
												marginTop: "7px",
											}}
										/>

										<RadioGroup
											className={classes.smallRadioButton}
											sx={{ marginLeft: "12px" }}
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
										>
											<FormControlLabel
												className={classes.smallRadioButton}
												value="%ofWOS"
												control={<Radio />}
												label="%ofWOS"
											/>
										</RadioGroup>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>

			<Divider light={true} variant="middle"></Divider>
			<div
				style={{ display: "flex", justifyContent: "center", padding: "5px" }}
			>
				<Button
					sx={{ marginRight: "8px" }}
					variant="outlined"
					onClick={() => {
						navigate(
							"/" +
								window?.location?.pathname?.split("/")[1] +
								"/" +
								window?.location?.pathname?.split("/")[2] +
								(data?.pathOnCancel||"/home"),
						);
					}}
				>
					Cancel
				</Button>

				<Button
					sx={{ marginLeft: "8px" }}
					variant="contained"
					onClick={() => {
						navigate(
							"/" +
								window?.location?.pathname?.split("/")[1] +
								"/" +
								window?.location?.pathname?.split("/")[2] +
								(data?.pathOnSave||"/home"),
						);
					}}
				>
					Save changes
				</Button>
			</div>
		</React.Fragment>
	);
};
