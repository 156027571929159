import { useReducer } from "react";

export const freezeState = {
    UNFREEZE: 0,
    FREEZE: 1
}

function reducer(state, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_FREEZE_STATE':
            const freezeColumn = { ...state[payload.id].freezeColumn, state: payload.state }
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    freezeColumn
                }
            }
        case 'CHECK_COLUMN_WIDTH':
            const columnWidth = { ...state[payload.id].columnWidth, checked: payload.checked }
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    columnWidth
                }
            }
        default:
            return state
    }
}

export default function ColumnMenu(initialState) {
    const [state, dispatch] = useReducer(reducer, initialState)

    function getMenuItems(params, props) {
        const colId = params.column.colId
        const colState = state[colId]
        const customItems = {
            "freezeColumn": {
                name: colState.freezeColumn.state == freezeState.FREEZE ? 'Unfreeze Column' : 'Freeze Column',
                action: () => {
                    const curState = colState.freezeColumn.state
                    if (curState == freezeState.FREEZE) {
                        params.columnApi.applyColumnState({ state: [{ colId, pinned: null }] })
                        dispatch({
                            type: "SET_FREEZE_STATE",
                            payload: {
                                id: colId,
                                state: freezeState.UNFREEZE
                            }
                        })
                    } else {
                        params.columnApi.applyColumnState({ state: [{ colId, pinned: 'left' }] })
                        dispatch({
                            type: "SET_FREEZE_STATE",
                            payload: {
                                id: colId,
                                state: freezeState.FREEZE
                            }
                        })
                    }
                }
            },
            "autoSizeAll": "autoSizeAll",
            "columnWidth": {
                name: "Column Width",
                subMenu: [
                    {
                        name: "Auto Adjust",
                        action: () => {
                            params.columnApi.autoSizeColumn(params.column)
                            dispatch({
                                type: 'CHECK_COLUMN_WIDTH',
                                payload: {
                                    id: colId,
                                    checked: 'autoAdjust'
                                }
                            })
                        },
                        checked: colState.columnWidth.checked == "autoAdjust" ? true : false
                    },
                    {
                        name: "Custom Width",
                        action: () => {
                            dispatch({
                                type: 'CHECK_COLUMN_WIDTH',
                                payload: {
                                    id: colId,
                                    checked: 'customWidth'
                                }
                            })
                        },
                        checked: colState.columnWidth.checked == "customWidth" ? true : false
                    }
                ]
            }
        }
        if (props?.customColumnMenu) {
            return props.customColumnMenu.map(name => customItems[name])
        }
        return params.defaultItems
    }
    return getMenuItems
}
