import React, { useState } from "react";
import {
  Grid,
  Card,
  Switch,
  Typography,
  Box,
  FormControlLabel,
} from "@mui/material";
import CustomKpiCard from "../../../components/CustomKpiCard";
import CustomPromoKpiCard from "../../../components/CustomPromoKpiCard";
import CustomOrderKpiCard from "../../../components/CustomOrderKpiCard";
import CustomReportKpiCard from "../../../components/CustomReportKpiCard";
import CustomGroceryKpiCard from "../../../components/CustomGroceryKpiCard/CustomGroceryKpiCard";
import Text from "../../../utils/Text";
import CustomPriceSmartGroceryCard from "./CustomPriceSmartGroceryCard";

const KpiCardComponent = (props) => {
  const { data } = props;
  const [kpisToggle, setKpisToggle] = useState(false);

  const loadCardUi = (data) => {
    switch (data?.uniqueKey) {
      case "grocery-price":
        return (
          <Card
            sx={{
              padding: 1,
              height: data?.height || "100%",
              overflowY: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {data?.header?.title && (
                <Text
                  style={
                    data?.header?.titleStyle
                      ? data.header.titleStyle
                      : { fontWeight: "bold", fontSize: "12px" }
                  }
                >
                  {data.header?.title}
                </Text>
              )}
              {data?.header?.enableSwitch && (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Text style={data?.header?.labelStyle}>
                    {data?.header?.label}
                  </Text>
                  <FormControlLabel
                    control={
                      <Switch
                        sx={data?.header?.switchStyle}
                        id="switch"
                        size="small"
                        defaultChecked={data?.header?.defaultCheck}
                      />
                    }
                    label={
                      <Text style={data?.header?.endLabelStyle}>
                        {data?.header?.endLabel}
                      </Text>
                    }
                  />
                  {/* <Text style={data?.header?.endLabelStyle}>
                    {data?.header?.endLabel}
                  </Text> */}
                </Box>
              )}
            </Box>

            <Grid
              container
              spacing={data.uniqueKey === "grocery-price" ? 0.5 : 2}
              md={"auto"}
              sx={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {data.data.map((obj, index) => (
                <Grid
                  item
                  xs={12}
                  // md={data?.style?.md || 2.6}
                  key={`data-card-${index}`}
                >
                  <div style={data.style}>
                    <Card>
                      {data.uniqueKey === "Promo" ? (
                        <CustomPromoKpiCard data={obj} values={data} />
                      ) : data.uniqueKey === "Order" ? (
                        <CustomOrderKpiCard
                          data={obj}
                          values={data}
                          toggle={kpisToggle}
                        />
                      ) : data.uniqueKey === "Report" ? (
                        <CustomReportKpiCard data={obj} value={data} />
                      ) : data.uniqueKey === "grocery-price" ? (
                        <CustomGroceryKpiCard data={obj} values={data} />
                      ) : (
                        <CustomKpiCard data={obj} values={data} />
                      )}
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Card>
        );

      case "pricesmart-Grocery":
        return (
          <CustomPriceSmartGroceryCard
            data={props.data.data}
            parentStyle={data?.parentStyle}
          />
        );

      default:
        return renderContainer(data.containerType)
    }
  };

  const loadCustomCards = (data, obj) => {
    switch (data?.uniqueKey) {
      case "Promo":
        return <CustomPromoKpiCard data={obj} values={data} />;
      case "Order":
        return (
          <Card>
            <CustomOrderKpiCard data={obj} values={data} toggle={kpisToggle} />
          </Card>
        );
      case "Report":
        return (
          <Card>
            <CustomReportKpiCard data={obj} value={data} />
          </Card>
        );
      default:
        return (
          <Card>
            <CustomKpiCard data={obj} values={data} />
          </Card>
        );
    }
  };
  function renderContainer(containerType) {
    switch(containerType){
      case "horizontal-scroll":
        return <div className="kpi-scroll--container">
          {data.data.map((obj, index) =>
            <div key={`data-card-${index}`}
              className="kpi-scroll--card" style={data?.style}>
            {loadCustomCards(data,obj)}
            </div>
          )}
        </div>
      default: {
        return (
          <Grid container spacing={2}>
            {data.data.map((obj, index) => (
              <Grid
                item
                xs={12}
                lg={data?.style?.md || 6}
                xl={data?.style?.md || 5}
                md={data?.style?.md || 2.6}
                key={`data-card-${index}`}
              >
                {loadCustomCards(data, obj)}
              </Grid>
            ))}
          </Grid>
        );
      }
    }
  }
  return (
    <>
      {data.switch ? (
        <Typography
          className="impact impact-order-management-typography"
          sx={{ width: "100%", flexShrink: 0 }}
        >
          <Grid container spacing={2}>
            {<Grid item xs={10}>
              <p>{data.hasOwnProperty("title")?data.title:"KPI's"}</p>
            </Grid>}
            <Grid item xs={2}>
              <div style={{ textAlign: "right" }}>
                {data.switch[0]}
                <Switch
                  defaultChecked={kpisToggle}
                  className="switch"
                  id="kpisCostToggleBtn"
                  onChange={(event) => {
                    setKpisToggle(event.target.checked);
                  }}
                ></Switch>
                {data.switch[1]}
              </div>
            </Grid>
          </Grid>
        </Typography>
      ) : null}
      {loadCardUi(data)}
    </>
  );
};

export default KpiCardComponent;
