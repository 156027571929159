import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomButton from "../../components/CustomButton";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";

const styles = (theme) => ({
	filtersHeader: {
		...theme.content.flexStyles.flexRow,
		...theme.content.flexStyles.flexAlignStartCenter,
		float: "right",
		text: "right",
	},
	filtersHeaderTitle: {
		...theme.typography.fontSizes.sectionHeaderText,
		margin: "0",
		marginRight: 48,
		fontWeight: theme.typography.fontWeight.bold,
	},
});

const useStyles = makeStyles(styles);

const FiltersHead = ({
	filtersExpanded,
	setFiltersExpanded,
	screenName,
	type,
}) => {
	const classes = useStyles();

	return (
		<>
			{type == "Accordion"  ? (
				<>
					<div className={classes.filtersHeader}>
						<p className={classes.filtersHeaderTitle}>
							{screenName || "Executive Summary"}
						</p>
						<CustomButton
							isPrimary={false}
							variant="outlined"
							height={32}
							width={104}
							label="Filters"
							startIcon={<FilterListIcon />}
							onButtonClick={() => setFiltersExpanded(!filtersExpanded)}
						/>
					</div>
				</>
			) : (
				<>
				{/* {!filtersExpanded && ( */}
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={10}>
							<p className={classes.filtersHeaderTitle}>
								{screenName || "Executive Summary"}
							</p>
						</Grid>
						<Grid item xs={2}>
							<div className={classes.filtersHeader}>
								<CustomButton
									isPrimary={true}
									variant="contained"
									height={36}
									width={154}
									label="Select Filters"
									startIcon={<FilterAltOutlined />}
									onButtonClick={() => setFiltersExpanded(!filtersExpanded)}
								/>
							</div>
						</Grid>
					</Grid>
					{/* )} */}
				</>
			)}
		</>
	);
};

export default FiltersHead;
