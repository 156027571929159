import React from "react";
import Checkbox from "@mui/material/Checkbox";

const CellCheckboxField = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox defaultChecked={props.value === "TRUE"} />
    </div>
  );
};

export default CellCheckboxField;
