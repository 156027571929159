import { lightGreen } from "@mui/material/colors";
import React from "react";

function ColumnColorRange(props) {
  const val = props.value || "";
  const varInArr = val.split(",");


  const cellStyle = (color) => ({
    background: color || "transparent",
    textAlign: "center"
   
  });

  const tableStyle = {
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  };

  return (
    <div>
      <table style={tableStyle}>
        <tbody>
          <tr>
            {varInArr.map((el, index) => {
              if (index % 2 === 0 && el !== "") {
                const color = varInArr[index + 1];
                return (
                  <td key={index} style={cellStyle(color)}>
                    {el}
                  </td>
                );
              } else {
                return null;
              }
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ColumnColorRange;
