import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PlaceholderImage from "../../../assets/placeholder.png";
const ImageDisplay = (props) => {
  const navigate = useNavigate();

  return (
    <div>
        <div style={props.data.style}>
          <img
            width={
              props.data.imageWidth
            }
            height={
              props.data.imageHeight
            }
            src={require(`../../../assets/images/assort/${props.data.img}.png`)}
            alt=""
            onClick={()=>{
              if(props.data["action"]){
                if(props.data.action["actionName"] === "navigate"){
                  navigate(
                    "/" +
                      window?.location?.pathname?.split("/")[1] +
                      "/" +
                      window?.location?.pathname?.split("/")[2] +
                      props.data.action.path
                  );
                }
              }
            }}
          />
        </div>
    </div>
  );
};

export default ImageDisplay;
