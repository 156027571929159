import React, { lazy } from "react";
import "./style.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PercentLine from "../../assets/svg/percent.svg";
import ChartLine from "../../assets/svg/chart-line.svg";
import ChartBellCurve from "../../assets/svg/chart-bell-curve.svg";
import ShowChart from "../../assets/svg/show_chart.svg";
import Logistics from "../../assets/svg/logistics.svg";
import StatsGraph from "../../assets/svg/stats-graph.svg";
const PercentIcon = lazy(() => import("@mui/icons-material/Percent"));

const returnIcon = (icon, color) => {
  switch (icon) {
    case "percent-line":
      return <img src={PercentLine} />;
    case "chart-line":
      return <img src={ChartLine} />;
    case "chart-bell-curve":
      return <img src={ChartBellCurve} />;
    case "logistics":
      return <img src={Logistics} style={{ width: "22px" }} />;
    case "show-chart":
      return <img src={ShowChart} style={{ width: "25px" }} />;
    case "stats-graph":
      return <img src={StatsGraph} style={{ width: "35px" }} />;
    case "percent":
      return (
        <PercentIcon sx={{ color: color, height: "2rem", width: "2rem" }} />
      );
    default:
      return "";
  }
};

export default function ForecastKpiCard({ data }) {
  return (
    <div className="forecastKpiCard" style={data?.wrapperStyle}>
      {data?.components.map((item) => {
        return (
          <div
            className={`forecast-container ${item.border ? "border" : null}`}
          >
            <div
              className="forecast-icon-container"
              style={{ backgroundColor: item.iconBGColor }}
            >
              {returnIcon(item.icon, item?.iconColor)}
            </div>
            <div className="forecast-label-container">
              <div className="forecast-label">{item.label}</div>
              <div className="forecast-values">
                <div className="forecast-main-values">{item.mainValue}</div>
                <div
                  className={`forecast-profit-values ${item.profitType === "profit" ? "profit" : "loss"}`}
                >
                  {item.profitValue}
                </div>
                {!item?.hideIcon && (
                  <div className="forecast-profit-icon">
                    {item.profitType === "profit" ? (
                      <TrendingUpIcon style={{ color: "#24a148" }} />
                    ) : (
                      <TrendingDownIcon style={{ color: "#DA1E28" }} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
