import { useMemo } from "react";
import GroupCellRenderer from "./GroupCellRenderer";
export default function GroupValueSwitch(params) {
  const rowGroupColumns = params.columnApi.getRowGroupColumns();
  const rowGroups = useMemo(() => {
    return rowGroupColumns.map((obj) => obj.colId);
  }, [rowGroupColumns]);
  const isGroup = params.node.group;
  const value = isGroup
    ? params.node.allLeafChildren[0].data[params.colDef.field]
    : params.value;
  const targetColumn = params.groups[isGroup ? "group" : "value"];
  return (
    rowGroups.includes(targetColumn) &&
    (params.rendererType === "groupCellRenderer" ? (
      <GroupCellRenderer {...params} />
    ) : (
      <div>{value}</div>
    ))
  );
}
