import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ComponentResolver from "../Resolver/ComponentResolver";
import { Grid } from "@mui/material";
import CustomImage from "./CustomImage";

const CustomAccordian = (props) => {
  const { data } = props;
  const overrideStyle = data?.overrideStyle ? {
    borderRadius: 0,
    boxShadow: 'none'
  } : null
  const { formEditedValues } = useSelector((state) => state.home);
  return (
    <div >
      <Accordion
        style={{
          borderLeft: "2px solid rgb(0, 85, 175)",
          ...overrideStyle
        }}
        disabled={data.disabled}
        defaultExpanded={data["defaultOpen"] ? true : false}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />}>
          {data.customHeaders ? (
            <Grid container spacing={1} style={data.style}>
              {data.customHeaders.map((obj) => (
                <>
                  {obj.size ? (
                    <Grid
                      item
                      md={obj.size}
                      xl={obj.size}
                      lg={obj.size}
                      sx={obj.style}
                    >
                      <Typography style={obj.style}>
                        {obj.label ? (
                          <>{obj?.label}</>
                        ) : (
                          <>
                            {formEditedValues[`${obj.mappingKey}`] ? (
                              formEditedValues[`${obj.mappingKey}`]
                            ) : obj.default_value ? (
                              <>{obj.default_value}</>
                            ) : obj.image ? (
                              <CustomImage data={obj.image} />
                            ) : 
                            (
                              <ComponentResolver
                                pageObject={{
                                  components: obj.components,
                                }}
                              />
                            )}
                          </>
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs>
                      <Typography style={obj.style}>
                        {obj.label ? (
                          <>{obj.label}</>
                        ) : (
                          <>
                            {formEditedValues[`${obj.mappingKey}`] ? (
                              formEditedValues[`${obj.mappingKey}`]
                            ) : obj.default_value ? (
                              <>{obj.default_value}</>
                            ) : obj.image ? (
                              <CustomImage data={obj.image} />
                            ) : (
                              <>
                                <ComponentResolver
                                  pageObject={{
                                    components: obj.components,
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Typography>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          ) : (
            <Typography style={data.labelStyle}>{data.label}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ComponentResolver pageObject={{ components: data.components }} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default CustomAccordian;
