import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import { Table, IconButton } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import "./chatbotStyles.css";
import { useEffect, useState, useRef } from "react";
import { Button as MuiButton } from "@mui/material";
import ParentCard from "./parentCard";
import { getAuthToken, getChatResponse, updateMessage } from "./utils";
import AutoComplete from "./autoComplete";
import ChatBotIcon from "../../assets/images/chatboticon.png";
import responseData from "./reponse.json";
import BatteriesPlusResponseData from "./response_batteries_plus.json";
import StellantisAdaResponseData from "./response_stellantis_ada.json";
import Steps from "./Steps";
import CircleIcon from "@mui/icons-material/Circle";

const Chatbot = ({ data }) => {
  const props = data?.info;
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [auth, setAuth] = useState(false);
  const messageInputRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [messages, setMessages] = useState(getInitialMessage);
  const [activeFlow, setActiveFlow] = useState("insights");
  const [activeResponse, setActiveResponse] = useState(responseData);

  useEffect(() => {
    document.getElementById("chat-action-wrapper").scrollIntoView();
  }, [messages]);
  useEffect(() => {
    (async () => {
      const auth = await getAuthToken();
      if (auth?.status === true) {
        setAuth(auth);
      } else {
        setAuth(false);
        setMessages([
          ...messages,
          {
            message:
              "There seem to be some problem authenticating the user. Please try logging back in.",
            sentTime: new Date().toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
            sender: "ChatGPT",
            type: "cards",
          },
        ]);
      }
    })();

    if (props?.responseKey) {
      switch (props?.responseKey) {
        case "batteries_plus_gen_inv_sm": {
          setActiveResponse(BatteriesPlusResponseData);
          break;
        }
        case "stellantis_ada": {
          setActiveResponse(StellantisAdaResponseData);
          break;
        }
        default: {
          setActiveResponse(responseData);
          break;
        }
      }
    }
  }, []);
  const handleCloseChat = () => {
    setShowChatWindow(false);
    setMessages(getInitialMessage());
  };
  const messageResolver = (messageItem, messageIndex) => {
    switch (messageItem?.type) {
      case "text":
        return (
          <>
            <p className="msg-text">
              {messageItem?.message?.data || messageItem?.message}
            </p>
          </>
        );
      case "cards": {
        return (
          <div>
            <p className="msg-text mg-t">
              {messageItem?.message?.resonse_heading}
            </p>
            <div className="card-wrapper">
              {messageItem?.message?.options?.map((cardItem) => (
                <div
                  className="card-item"
                  onClick={() => {
                    if (messageInputRef?.current) {
                      // messageInputRef.current.value = `${cardItem}`;
                      handleMessageSend(cardItem);
                    }
                    // messageInputRef?.current?.focus();
                  }}
                >
                  {typeof cardItem === "object" ? cardItem.name : cardItem}
                </div>
              ))}
            </div>
          </div>
        );
      }
      case "question":
        return (
          <div>
            <p className="msg-text">{messageItem?.message?.resonse_heading}</p>
            <div className="ques-wrapper">
              {messageItem?.message?.options?.map((cardItem, index) => (
                <div className="ques-item">
                  <p className="ques-label">Ques {index + 1}.</p>
                  <p
                    className="ques-value"
                    onClick={() => {
                      if (messageInputRef?.current) {
                        // messageInputRef.current.value = `${cardItem}`;
                        handleMessageSend(cardItem);
                      }
                      // messageInputRef?.current?.focus();
                    }}
                  >
                    {typeof cardItem === "object" ? cardItem.name : cardItem}
                  </p>
                </div>
              ))}
            </div>
          </div>
        );
      case "table":
        const createTable = (data) => {
          let keys = Object.keys(data?.message?.data[0]);
          return (
            <>
              {data?.message?.resonse_heading ? <p className="std-text">{data?.message?.resonse_heading}</p> : <p className="std-text">Here is your insight: </p>}
              <Table
                // borderCollapse: "collapse",
                style={{
                  width: "100%",
                  borderColor: "#BCD0E0",
                  borderRadius: "10px",
                  outline: "none",
                }}
                className="mg-t"
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#C6DCFC",
                      border: "2px solid red!important",
                    }}
                  >
                    {keys.map((item) => {
                      var name = item?.replace("_", " ");
                      return (
                        <TableCell
                          style={{
                            textAlign: "left",
                            border: "1px solid #BCD0E0",
                            fontSize: "0.9rem",
                            padding: "0.75rem",
                            fontWeight: 600,
                          }}
                        >
                          {name.replace(/_/g, " ")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                {data?.message?.data?.map((item, index) => {
                  return (
                    <TableRow className="cb-table-data-rows">
                      {keys.map((ele) => {
                        return (
                          <TableCell
                            style={{
                              border: "none",
                              border: "1px solid #BCD0E0",
                              fontSize: "0.8rem",
                              padding: "0.75rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {item[ele]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </Table>
              {data?.message?.bottom_msg ? 
              <p className="std-text mg-t">{data?.message?.bottom_msg}</p> :
              <p className="std-text mg-t">
                Since I'm an automated chatbot, I can make mistakes. Please
                re-check critical information/steps. Though I am constantly
                learning and getting better
              </p>}
            </>
          );
        };
        return <>{createTable(messageItem)}</>;
      case "steps": {
        return (
          <Steps
            item={messageItem}
            handleClick={(stepIndex) => {
              setMessages((prev) => {
                const msgs = prev.slice();
                const step = msgs[messageIndex].message?.steps?.[stepIndex];
                if (step) {
                  step.complete = !step.complete;
                }
                return msgs;
              });
            }}
          />
        );
      }
      case "dict":
        const keys = Object.keys(messageItem?.message?.data);
        return (
          <div>
            <p className="std-text">Here is your insight: </p>
            <p className="msg-text mg-t">
              {messageItem?.message?.resonse_heading}
            </p>
            <ul className="list-wrapper" style={{ listStyletype: "circle" }}>
              {keys?.map((element) => {
                return (
                  <li className="list-item">
                    <CircleIcon className="bullet" />
                    <p className="list-label">{element?.replace("_", " ")}</p>
                    <p className="list-value">
                      {messageItem?.message?.data[element]}
                    </p>
                  </li>
                );
              })}
            </ul>
            <p className="std-text mg-t">
              Since I'm an automated chatbot, I can make mistakes. Please
              re-check critical information/steps. Though I am constantly
              learning and getting better
            </p>
          </div>
        );
      default:
        return null;
    }
  };
  const handleMessageSend = async (val) => {
    setIsloading(true);
    if (typeof val === "string") {
      val = { name: val, id: val };
    }
    const newMessage = {
      message: val.name,
      sentTime: new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      sender: "user",
      type: "text",
    };
    setMessages([...messages, newMessage]);
    setTimeout(() => {
      let messageObj = activeResponse[val.id];
      if (val?.id === "general_navigation") {
        setActiveFlow("general_navigation");
      } else if (val?.id === "insights") {
        setActiveFlow("insights");
      }
      if (messageObj) {
        messageObj = updateMessage(messageObj);
        setMessages([
          ...messages,
          newMessage,
          {
            ...messageObj,
            sentTime: new Date().toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
          },
        ]);
        setIsloading(false);
      } else {
        (async () => {
          const messageResponse = await getChatResponse(
            val.name,
            auth?.response?.access_token,
          );
          if (messageResponse?.response?.status === 401) {
            setAuth(false);
          }

          if (messageResponse?.status === true) {
            if (messageResponse?.is_error) {
              setMessages([
                ...messages,
                newMessage,
                {
                  message:
                    "I'm sorry, but I couldn't understand your request. Can you please provide more information or clarify your question?",
                  sentTime: new Date().toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  sender: "ChatGPT",
                  type: "text",
                },
              ]);
            } else {
              setMessages([
                ...messages,
                newMessage,
                {
                  message: {
                    resonse_heading:
                      messageResponse?.response_heading || "Response message",
                    data: messageResponse?.response?.response,
                  },
                  sentTime: new Date().toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }),
                  sender: "ChatGPT",
                  type: messageResponse?.response?.response_type,
                },
              ]);
            }
          } else {
            setMessages([
              ...messages,
              newMessage,
              {
                message:
                  "There seem to be some problem right now. Please try after some time",
                sentTime: new Date().toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }),
                sender: "ChatGPT",
                type: "text",
              },
            ]);
            setIsloading(false);
          }

          // }
          setIsloading(false);
        })();
      }
    }, 1500);
    messageInputRef.current.value = "";
  };
  return (
    <>
      <div className="chatBubble" onClick={() => setShowChatWindow(true)}>
        <img src={ChatBotIcon} className="chatIcon" />
        {!props?.disableIconButtonText && <p>Ask me anything about tool</p>}
      </div>
      {/* CHAT BODY  */}
      <div
        className="chatWindow"
        style={{ display: showChatWindow ? "block" : "none" }}
      >
        <div className="chatbotHeader">
          <p>
            <CircleIcon
              className="auth-status"
              sx={{ color: auth === false ? "red" : "green" }}
            />
            {"SmartSage :)"}{" "}
          </p>
          <div>
            <RemoveIcon
              className="close-icon"
              onClick={() => setShowChatWindow(false)}
            />
            <CloseIcon className="close-icon" onClick={handleCloseChat} />
          </div>
        </div>
        {/* CHAT MESSAGES WRAPPER */}
        <div className="message-container">
          {messages?.map((item, index) => {
            const sender = item?.sender === "user" ? "user" : "ChatGPT";
            return (
              <>
                {sender === "ChatGPT" ? (
                  <>
                    <div className={`${sender} msg-bubble`}>
                      <div className="msg-bubble-header">
                        {sender === "ChatGPT" && (
                          <p className="sender-name">SmartSage</p>
                        )}
                        <p className="timestamp">
                          {item?.sentTime?.slice(0, 5)}
                        </p>
                      </div>
                      {messageResolver(item, index)}
                    </div>
                  </>
                ) : (
                  <div className={`${sender} msg-bubble`}>
                    <div className="msg-bubble-header">
                      {messageResolver(item)}
                      <p className="timestamp">{item?.sentTime?.slice(0, 5)}</p>
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {isLoading && (
            <div className={`ChatGPT msg-bubble`}>
              <div className="msg-bubble-header">
                <p className="sender-name">SmartSage</p>
                <p className="timestamp">
                  {new Date()
                    .toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                    ?.slice(0, 4)}
                </p>
              </div>
              <div id="wave" className="mg-t">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </div>
          )}
          <div id={"chat-action-wrapper"}>
            <p onClick={handleCloseChat}>End Chat</p>
            <p>Save Chat</p>
          </div>
        </div>
        {showSuggestions && (
          <AutoComplete
            props={{
              showSuggestions,
              setShowSuggestions,
              inputfieldRef: messageInputRef,
              activeFlow,
              responseKey : props?.responseKey
            }}
          />
        )}
        <div className="bottom-container">
          {/* MESSAGE INPUT CONTAINER  */}
          <div className="msg-input-container">
            <input
              type="text"
              className="msg-inputfield"
              placeholder="Type your questions here.."
              ref={messageInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleMessageSend(messageInputRef?.current?.value);
                }
                if (messageInputRef.current.value.length > 1) {
                  setShowSuggestions(messageInputRef.current.value);
                }
                if (messageInputRef.current.value === "") {
                  setShowSuggestions(false);
                }
              }}
              onChange={() => {
                if (messageInputRef.current.value === "") {
                  setShowSuggestions(false);
                }
              }}
            />
            <MuiButton
              className={"send-btn"}
              startIcon={<SendIcon sx={{ color: "white" }} />}
              onClick={() => handleMessageSend(messageInputRef?.current?.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
function getInitialMessage() {
  return [
    {
      message:
        "Hi User! I am SmartSage - your digital sherpa. I am here to help you with anything on IA Smart Platform.",
      sentTime: new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      sender: "ChatGPT",
      type: "text",
    },
    {
      message: {
        resonse_heading: "Please select what information you want to look for?",
        options: [
          { name: "Insights", id: "insights" },
          { name: "General Navigation", id: "general_navigation" },
        ],
      },
      sentTime: new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      sender: "ChatGPT",
      type: "cards",
    },
  ];
}
export default Chatbot;
