import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMap from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/countries/us/us-all.geo.json";
import proj4 from "proj4";
import { getMultipleModelsData } from "../../pages/Home/action";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
HighchartsMap(Highcharts);

const Map = ({ info }) => {
	const dispatch = useDispatch();
	const { key, title } = info;
	const { selectedRowInfo, tableInfo } = useSelector((state) => state.home);
	const [mapSeries, setMapSeries] = useState([]);
	const [options, setOptions] = useState({});
	const [legends, setLegends] = useState([]);
	useEffect(() => {
		if (key) {
			getMultipleModelsData({ model_names: [key] }).then((res) => {
				// setMapInfo([...res[key]]);
				let params = {};
				params[`${key}`] = [...res[key]];
				params[`${key}_copy`] = [...res[key]];
				dispatch({
					type: "TABLE_DATA",
					payload: params,
				});
			});
		}
	}, [key]);

	useEffect(() => {
		if (tableInfo[`${key}`]) {
			const series = [];
			tableInfo[`${key}`].forEach((element) => {
				const { StoreID, Color, Cluster, name } = element;
				series.push({
					id: StoreID + "",
					type: "mappoint",
					name: name,
					// linkedTo: Cluster,
					showInLegend: false,
					dataLabels: {
						enabled: true,
					},
					marker: {
						symbol: "circle",
					},
					color: Color,
					tooltip: {
						pointFormat: `Store Number: {point.StoreID}<br /> City: {point.City}<br /> Weekly Revenue: {point.WeeklyRevenue}<br /> Store Square footage: {point.StoreSquareFootage}`,
					},
					stickyTracking: false,
					data: [
						{
							...element,
							lat: parseInt(element.lat),
							lon: parseInt(element.lon),
						},
					],
				});
			});
			const uniqueLegends = _.uniqBy(tableInfo[`${key}`], function (e) {
				return e.Cluster && e.Color;
			});
			setLegends([...uniqueLegends]);
			setMapSeries(series);
		}
	}, [tableInfo]);

	useEffect(() => {
		setOptions({
			chart: {
				map: "countries/us/us-all",
				proj4,
			},
			title: {
				text: title,
			},
			mapNavigation: {
				enabled: true,
				buttonOptions: {
					verticalAlign: "bottom",
				},
			},
			series: [
				{
					name: "Countries",
					nullColor: "#fff",
					showInLegend: false,
					mapData: mapData,
				},
				...mapSeries,
			],
		});
	}, [mapSeries]);

	return (
		<>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				constructorType={"mapChart"}
			/>
			<div
				style={{
					textAlign: "center",
					paddingTop: 18,
				}}
			>
				{legends.map((obj, idx) => (
					<>
						<span
							className="dot"
							style={{
								backgroundColor: obj.Color,
								marginTop: 5,
								marginRight: 5,
							}}
						></span>
						<label style={{ paddingRight: 20, fontSize: 12 }}>
							{"Cluster"} {`${obj.Cluster}`}
						</label>
					</>
				))}
			</div>
		</>
	);
};

export default Map;
