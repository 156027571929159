import { ArcherContainer, ArcherElement } from "react-archer";
import "./style.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Node, Scrollbox } from "./Node";

export default function ArcherField({ data }) {
  const [info] = useState(data.info);
  const nodeRefs = useRef({});
  const labelRefs = useRef({});

  const configureLabel = useCallback((sourceId, relation) => {
    const anchor = {
      top: {
        x: 0.5,
        y: 0,
      },
      bottom: {
        x: 0.5,
        y: 1,
      },
      left: {
        x: 0,
        y: 0.5,
      },
      right: {
        x: 1,
        y: 0.5,
      },
    };
    const fn = () => {
      const sourceEL = nodeRefs.current[sourceId];
      const targetEl = nodeRefs.current[relation.targetId];
      const startPos = {
        x:
          sourceEL.offsetLeft +
          anchor[relation.sourceAnchor].x * sourceEL.clientWidth,
        y:
          sourceEL.offsetTop +
          anchor[relation.sourceAnchor].y * sourceEL.clientHeight,
      };
      const endPos = {
        x:
          targetEl.offsetLeft +
          anchor[relation.targetAnchor].x * targetEl.clientWidth,
        y:
          targetEl.offsetTop +
          anchor[relation.targetAnchor].y * targetEl.clientHeight,
      };
      const angle = Math.atan(
        (endPos.y - startPos.y) / (endPos.x - startPos.x)
      );
      const labelEl = labelRefs.current[relation.targetId];
      labelEl.style.transform = `rotate(${angle}rad)`;
    };
    setTimeout(fn, 0);
  }, []);

  const parser = useCallback(
    (arr) => {
      return arr.map((node) => {
        node.relations?.forEach((relation) => {
          if (relation.label) configureLabel(node.id, relation);
        });
        return (
          <div
            ref={(el) => (nodeRefs.current[node.id] = el)}
            style={{
              maxWidth: "max-content",
              position: "absolute",
              ...getPos(node.position),
              transform: "translate(-50%,-50%)",
            }}
          >
            <ArcherElement
              id={node.id}
              relations={node?.relations?.map((relation) => ({
                ...relation,
                label: relation.label ? (
                  <div
                    ref={(el) => (labelRefs.current[relation.targetId] = el)}
                    style={{
                      paddingBottom: "20px",
                      width: "max-content",
                    }}
                  >
                    {relation.label}
                  </div>
                ) : (
                  <></>
                ),
              }))}
            >
              <div>{resolver(node.componentInfo)}</div>
            </ArcherElement>
          </div>
        );
      });
    },
    [configureLabel]
  );

  const graph = useMemo(() => {
    return (
      <ArcherContainer
        strokeColor="#ccc"
        strokeWidth={1}
        lineStyle="straight"
        className="archer-field"
      >
        {parser(info)}
      </ArcherContainer>
    );
  }, [parser, info]);

  return (
    <div className="archer-main-container" style={data.style}>
      {graph}
    </div>
  );
}

function getPos({ x, y }) {
  return {
    top: `${y}%`,
    left: `${x}%`,
  };
}
function resolver(info) {
  if (info) {
    switch (info.type) {
      case "node": {
        return <Node data={info} />;
      }
      case "scrollbox": {
        return <Scrollbox data={info} />;
      }
      default:
        return <div className="archer-node"></div>;
    }
  }
  return <div className="archer-node"></div>;
}
