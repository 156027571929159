import React, { Component } from "react";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import {
	FormControlLabel,
	Grid,
	InputLabel,
	Switch,
	TextField,
	InputAdornment,
} from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import Refresh from "@mui/icons-material/Refresh";
import "@trendmicro/react-tooltip/dist/react-tooltip.css";
import { Infotip } from "@trendmicro/react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import CheckboxGroup from "../../../components/CheckboxGroup";
import CustomRadio from "../CustomComponents/CustomRadio";
import CustomRadioGroup from "../CustomComponents/CustomRadioGroup";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CustomDatePicker from "../../../components/CustomDatePicker";
import Button from "@mui/material/Button";
import DropdownTreeSelectRenderer from "../../../components/dropdownTreeSelect";

export default class DynamicForms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.options,
			isLabelActionClicked: false,
		};
	}

	updateState = (params) => {
		this.props.updateParentState({
			...params,
		});
	};

	reset() {
		let resetObj = Object.assign({}, this.props.options);
		this.props.hierarchyApiCalls.forEach((obj) => {
			delete resetObj[`${obj.optionsMappingKey}Options`];
		});

		this.setState({
			...resetObj,
		});
		this.props.onReset();
	}

	handleCheckboxChange = (e, info, filterObj) => {
		const { name, value } = e.target;
		// let params = {};
		// if( filterObj.state.includes(name)){
		// 	params[`${filterObj.label}`] = filterObj.state.filter((obj) => obj !== name)
		// }else{
		// 	params[`${filterObj.label}`] = filterObj.state.concat(name)
		// }
		// const isExistedData = filterObj.state.filter((obj) => obj.label === name);
		// const notExistedData = filterObj.state.filter((obj) => obj.label !== name);
		// const isNewData = info.filter((obj) => obj.label === name);
		// let params = {};
		// if (isExistedData.length > 0) {
		// 	params[`${filterObj.label}`] = notExistedData;
		// } else {
		// 	params[`${filterObj.label}`] = filterObj.state.concat(isNewData);
		// }
		// this.updateState(params);
	};
	handleDateRangeChange = (values, filterObj) => {
		let params = {};
		params[`${filterObj.label}`] = values;
		this.updateState(params);
	};

	getFilterLabel = (obj) => {
		return (
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div>
					{obj.label}
					{obj.required ? <span style={{ color: "red" }}> * </span> : null}
				</div>
				<Infotip content={obj.infotip}>
					<InfoIcon color="primary" />
				</Infotip>
			</div>
		);
	};
	handleInputChange = (e, label) => {
		const { name, value } = e.target;
		let params = {};
		params[`${label}`] = value;
		this.updateState(params);
	};
	handleRadioChange = (e, info, filterObj) => {
		const { name, value } = e.target;
		let params = {};
		params[`${filterObj.label}`] = [value];
		this.updateState(params);
	};
	handleDateChange = (values, filterObj) => {
		let params = {};
		params[`${filterObj.label}`] = values;
		this.updateState(params);
	};
	onLinkClick = () => {
		this.setState({
			isLabelActionClicked: true,
		});
	};
	handleTextFieldWithLabel = (e, label) => {
		const { name, value } = e.target;
		let params = {};
		params[`${label}`] = value;
		this.props.requestToStore("formEditedValues", params);
	};


	renderFilters(obj) {
		switch (obj.type) {
			case "dropdown-tree-select":
				return (
					<DropdownTreeSelectRenderer
						data={this.state[`${obj.mappingKey}Options`]}
						filterLabel={obj.label}
						placeholder={obj.placeholder}
						isMandatory={obj.required}
						// onChange={this.onChange} onAction={this.onAction} onNodeToggle={this.onNodeToggle}
					/>
				);

			case "multi-select":
				return (
					<>
						{/* {JSON.stringify(this.state[`${obj.mappingKey}Options`])} */}
						<SelectRenderer
						{...obj}
							options={this.state[`${obj.mappingKey}Options`]}
							selectedItems={obj.state}
							isMandatory={obj.infotipEnabled ? false : obj.required}
							isMulti={true}
							placeholder={obj.placeholder}
							filterLabel={
								obj.infotipEnabled ? this.getFilterLabel(obj) : obj.label
							}
							updateSelected={(params) => {
								let updateData = {};
								updateData[`${obj.mappingKey}`] = params.selectedItems;
								this.updateState(updateData);
							}}
							horizontalView={this.props.horizontalView}
							isDisabled={obj.isDisabled}
						/>
					</>
				);
			case "single-select":
				return (
					<>
						<SelectRenderer
						{...obj}
							options={this.state[`${obj.mappingKey}Options`]}
							selectedItems={obj.state}
							isMandatory={obj.infotipEnabled ? false : obj.required}
							isMulti={false}
							filterLabel={
								obj.infotipEnabled ? this.getFilterLabel(obj) : obj.label
							}
							placeholder={obj.placeholder}
							updateSelected={(params) => {
								let updateData = {};
								updateData[`${obj.mappingKey}`] = params.selectedItems;
								this.updateState(updateData);
							}}
							horizontalView={this.props.horizontalView}
							isDisabled={obj.isDisabled}
						/>
					</>
				);
				break;
			case "date-range":
				return (
					<>
						<CustomDateRangePicker
							label={obj.label}
							labeldirection={"column"}
							values={obj.state}
							isMandatory={false}
							onChange={(values) => this.handleDateRangeChange(values, obj)}
							horizontalView={this.props.horizontalView}
						/>
					</>
				);
			case "date":
				return (
					<>
						<CustomDatePicker
							label={obj.label}
							labeldirection={"column"}
							values={(obj.state)}
							isMandatory={obj.is_required}
							// onChange={(value) => this.handleDateChange(value, obj)}
							horizontalView={this.props.horizontalView}
						/>
					</>
				);
			case "checkboxes":
				return (
					<>
						<CheckboxGroup
							label={obj.label}
							options={this.state[`${obj.mappingKey}Options`]}
							row={true}
							values={obj.id}
							handleChange={(e) => this.handleCheckboxChange(e, obj.state, obj)}
							horizontalView={this.props.horizontalView}
						/>
					</>
				);
			case "radio":
				return (
					<>
						<CustomRadioGroup
							label={obj.label}
							options={this.state[`${obj.mappingKey}Options`]}
							row={true}
							values={obj.id}
							handleChange={(e) =>
								this.handleRadioChange(
									e,
									this.state[`${obj.mappingKey}Options`],
									obj,
								)
							}
							horizontalView={this.props.horizontalView}
						/>
					</>
				);
			case "textarea":
				return (
					<>
						<InputLabel
							style={{
								lineHeight: "1.5",
								color: "#8596a9",
								fontSize: "0.9rem",
								marginBottom: "0.2rem",
							}}
							required={obj.required}
							placeholder={obj?.label}
						>
							<span title={obj.label}>{obj.label}</span>
						</InputLabel>
						<TextareaAutosize
							aria-label="empty textarea"
							placeholder="Empty"
							minRows={2}
							onChange={(e) => {
								this.handleInputChange(e, obj.label);
							}}
						/>
					</>
				);
				break;
			case "text":
				return (
					<>
						<InputLabel
							style={{
								lineHeight: "1.5",
								color: "#8596a9",
								fontSize: "0.9rem",
								marginBottom: "0.2rem",
							}}
							required={obj.required}
							placeholder={obj?.label}
						>
							<span title={obj.label}>{obj.label}</span>
						</InputLabel>
						<TextField
							id="standard-basic"
							variant="standard"
							defaultValue={obj.defaultValue ? obj.defaultValue : ""}
						/>
					</>
				);
			case "number":
				return (
					<>
						<TextFieldWithLabel
								type={obj.type}
								label={obj.label}
								width={obj.width}
								// {...obj}
								value={obj.state}
								horizontalView={this.props.horizontalView}
								defaultValue={obj.defaultValue}
								isStoreRequired={this.props.isStoreRequired}
								// onChange={this.handleTextFieldWithLabel}
								onChange={(e)=>{
									this.handleTextFieldWithLabel(e,obj.label)
								}}
								endAdornment={
									<InputAdornment position="end"><b>{obj.symbol}</b></InputAdornment>
								  }
								// endAdornment={
								// 	<InputAdornment position='end'>%</InputAdornment>
								//   }
							/>
					</>
				);
			case "label-action":
				return (
					<>
						<div
							onClick={this.props.onLabelActionClick}
							style={{ cursor: "pointer", color: "#0255AF" }}
						>
							{obj.label}
						</div>
					</>
				);
			case "switch":
				return (
					<span >
						{/* {obj.label}  &nbsp;&nbsp;<FormControlLabel control={<Switch  onClick={this.props.onLabelActionClick}/>}  /> */}
						{obj.label} &nbsp;&nbsp;{" "} <Switch
							label={obj.label}
							onClick={(e) => {
								this.props.onLabelActionClick(e);
							}}
						/>
						<div className="spacer"></div>
					</span>
				);
			case "button":
				return (
					<>
						<Button
							style={{ textTransform: "none", margin: 5 }}
							variant={"contained"}
							size={"medium"}
							title={obj.label}
							// disabled={obj.disableFeat && diable}
							onClick={() => {
								// if(obj['isStoreRequired']){
								// 	let params = {};
								// 	params[`${obj.label}`] = true;
								// 	params[`${obj.otherDependentKey}`] = false
								// 	this.props.requestToStore("dependentValues", params);
								// }
								this.props.onButtonClick(obj);
							}}
						>
							{obj.label}
						</Button>
					</>
				);
			case "NumberFormatter":
				return (
					<>
					
						{/* <div style={{ marginTop: '2rem', marginLeft:'2rem' }}> */}
						<TextFieldWithLabel
							type="number"
							label={obj.label}
							// onChange={(e) => setMaxValue(parseInt(e.target.value))}
							defaultValue={0}
							width={obj.width}
							endAdornment={<InputAdornment position="end">%</InputAdornment>}
						/>
						{/* </div> */}
					</>
				);
			case "textField":
					return (
					  <>
						  <TextFieldWithLabel
							type={"text"}
							label={obj.label}
							// onChange={(e) => setMaxValue(parseInt(e.target.value))}
							width={obj.width}
							defaultValue={obj.defaultValue}
						  />
					  </>
					);
			default:
				return <></>;
		}
	}
	render() {
		return (
			<div>
				<Grid container spacing={3} columns={12}>
					{this.props.forms.map((obj, index) => (
						<>
							<Grid item xs={((obj.grid) || 3)} sm={((obj.grid) || 3)} md={((obj.grid) || 3)} lg={((obj.grid) || 3)} xl={((obj.grid) || 3)} key={`decision-dashboard_filter-${obj.label}`}> 
								{this.renderFilters(obj)}
							</Grid>
						</>
					))}
					{this.props.isSubmitRequired ? (
						<>
							<Grid item md={3} xl={3} display={"flex"} alignItems={"flex-end"}>
								<Grid container>
									<Grid item xs={12} md={6}>
										<CustomButton
											isPrimary={true}
											variant="contained"
											height={36}
											width={104}
											label={this.props.submitButtonName || "Submit"}
											startIcon={<FilterAltOutlined />}
											onButtonClick={() => this.props.appliedFilters()}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<CustomButton
											isPrimary={false}
											variant="outlined"
											height={36}
											width={104}
											label={this.props.resetName || "Reset"}
											startIcon={<Refresh />}
											onButtonClick={() => this.reset()}
										/>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : null}
				</Grid>
			</div>
		);
	}
}
