//this file carries utils for external filtering of table

const parseCellValue = {
  tag: (value) => {
    return value.split(",")[0];
  },
};
function getComparableValue(rawValue, criteria) {
  if (rawValue) {
    const parser = parseCellValue[criteria];
    if (parser) {
      return parser(rawValue);
    }
  }
  return rawValue;
}
export { getComparableValue };
