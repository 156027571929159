import React, { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { makeStyles, styled } from "@mui/styles";
import moment from "moment";
import "./DatePicker.scss";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { Paper } from "@mui/material";
import { width } from "@mui/system";
import Text from "../../utils/Text";
import "./DatePicker.css";

const styles = (theme) => ({
	tab: {
		border: "4px solid #dfeaf5",
	},
});

const useStyles = makeStyles(styles);

const Item = styled(Paper)(({ theme, width, labeldirection }) => ({
	...theme.typography.body2,
	display: labeldirection === "row" && "flex",
	alignItems: labeldirection === "row" && "center",
	justifyContent: labeldirection === "row" && "space-between",
	textAlign: "left",
	boxShadow: "none",
	background: "transparent",
	// width,
	color: theme.palette.text.secondary,
}));

const CustomDateRangePicker = (props) => {
	const classes = useStyles();
	const {
		label,
		labeldirection,
		values,
		width,
		fieldWidth,
		isMandatory,
		onChange,
		format,
	} = props;

	return (
		<Item className="filterGroup" labeldirection={labeldirection} width={width}>
			<Text
				style={{
					marginRight: "10px",
					fontSize: "12px",
					color: "#758490",
					...props.labelStyle
				}}>
				{label}
				{isMandatory ? <span style={{ color: "red" }}> * </span> : null}
			</Text>
			<div style={{ width: fieldWidth, height: "35px" }}>
				<DateRangePicker
					style={{ height: "35px" }}
					onChange={onChange}
					value={values}
					clearIcon={null}
					dayPlaceholder={"dd"}
					monthPlaceholder={"mm"}
					yearPlaceholder={"yyyy"}
					format={format}
					calendarIcon={
						<CalendarMonthOutlined
							style={{ fontSize: 20, color: "#515151", ...props.iconStyle }}
						/>
					}
				/>
			</div>
		</Item>
	);
};

export default CustomDateRangePicker;
