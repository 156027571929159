import * as React from "react";
import { TextareaAutosize } from "@material-ui/core";

export default function TextArea(props) {
  const height = props.data?.info?.height;
return (
    <div>
      <TextareaAutosize
        // variant="outlined"
        multiline
        defaultValue={props.data.defaultValue}
        minRows={props.data.rows}
      style={height ?
        {
          width: props.data.width ? props.width : "100%",
          height: height
        }:{
          width: props.data.width ? props.width : "100%",
      }}
      />{""}
    </div>
  );
}
