import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import useAction from "../../../utils/useAction";
import { Button } from "impact-ui";
import Checkbox from "@mui/material/Checkbox";

export default function CellToggle(params) {
  const invokeAction = useAction();
  const [checked, setChecked] = useState(() => {
    const value = params.node.group
      ? params.node.allLeafChildren[0].data[params.colDef.field]
      : params.value;
    return value === "1" ? true : false;
  });

  const [disabled] = useState(() => {
    const rowData = params.node.group
      ? params.node.allLeafChildren[0].data
      : params.node.data;
    return rowData[`#disable_${params.colDef.field}`] === "Y";
  });

  function handleToggle(e) {
    setChecked((prev) => !prev);
    action(checked);
  }
  useEffect(() => {
    if (params.updateParentGrid) {
      const v = checked ? "1" : "0";
      params.setValue(v);
    }
  }, [params, checked]);

  function action(currentState) {
    if (params.switchAction) {
      const action = currentState
        ? params.switchAction[0]
        : params.switchAction[1];
      invokeAction(action);
    }
  }
  function renderer(type) {
    switch (type) {
      case "button":
        return <CustomButton value={params.value} />;
      case "checkbox":
        return <CustomCheckbox value={params.value} />;
      default:
        return (
          <Switch
            checked={checked}
            onClick={(e) => {
              handleToggle(e);
            }}
            disabled={disabled}
          />
        );
    }
  }
  function handleClick(value) {
    if (value == "0") {
      params.setValue("1");
    } else {
      params.setValue("0");
    }
  }
  function CustomButton({ value }) {
    const Label = value == "0" ? "Add" : "Remove";
    return (
      <Button
        variant="primary"
        onClick={() => handleClick(value)}
        disabled={disabled}
      >
        {Label}
      </Button>
    );
  }

  function CustomCheckbox({ value }) {
    const defaultValue = value == "0" ? false : true;
    return <Checkbox defaultChecked={defaultValue} disabled={disabled} />;
  }
  function isHidden(params) {
    if (params?.hideForGroup && params.node.group) {
      return true
    }
    const v = params?.data?.[`#hideRenderer_${params.colDef.field}`]
    return (v && v === "Y") ? true : false
  }
  return isHidden(params) ? null:renderer(params?.rendererType)
}
