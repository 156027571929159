import React,{useEffect} from "react";
import { makeStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
const styles = (theme) => ({
  tab: {
    border: "4px solid #dfeaf5",
  },
});

const useStyles = makeStyles(styles);

const CustomRadioGroup = (props) => {
  const { options, label, row, value, handleChange, checkedValue } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const handleChangeAction = (e) => {
    if (props.handleChange) {
      props.handleChange(e);
    }
  };
  useEffect(() => {
    return () => {
      if (props?.clearDependentsOnDestroy) {
        const params={}
        options?.forEach(obj => params[obj.value] = false)
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params
        })
      }
    }
  },[])
  return (
    <>
      {props.horizontalView  ? (
        <>
          <Grid container style={{...props.wrapperStyle}}>
            <Grid item xs={3} sm={3} md={3}>
              {label ? (
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ ...props.labelStyle }}>
                  {label}
                </FormLabel>
              ) : null}
            </Grid>
            <Grid item xs>
              <RadioGroup
                row={row}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={( label || "row-radio-buttons-group")}
                value={value}
                onChange={handleChange}
              >
                {options?.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    key={`radio-${option.value}`}
                    onChange={handleChangeAction}
                    checked={
                      option.value === checkedValue ||
                      option.is_default_value === "Y"
                    }
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <FormControl>
            {label ? (
              <FormLabel id="demo-row-radio-buttons-group-label" style={{ ...props.labelStyle }}>
                {label}
              </FormLabel>
            ) : null}

            <RadioGroup
              row={row}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {options?.map((option) => (
                <FormControlLabel
                  disabled={option.disabled}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  key={`radio-${option.value}`}
                  onChange={handleChangeAction}
                  checked={
                    option.value === checkedValue ||
                    option.is_default_value === "Y"
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      )}
    </>
  );
};

export default CustomRadioGroup;
