import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import SnackBar from "../../../components/SnackBar";

export default function CalendarEventsColorInfo({ eventStatusColors }) {
	const [showMessage, setShowMessage] = useState(false);

	const handleSave = () => {
		setShowMessage(true);
	};

	const handleMessageClose = () => {
		setShowMessage(false);
	};
	return (
		<div
			className="event-legend-container"
			style={{
				display: "flex",
				alignItems: "center",
				gap: "200px",
			}}>
			<div className="event-legend">
				{Object.keys(eventStatusColors).map((status) => (
					<div className="event-legend-content">
						<div
							className="event-legend-content-circle"
							style={{
								backgroundColor: eventStatusColors[status].color,
								border: `1px solid ${eventStatusColors[status].border}`,
							}}
						/>
						<div
							className="event-legend-content-text"
							style={{
								color: eventStatusColors[status].border,
							}}>
							{eventStatusColors[status].label}
						</div>
					</div>
				))}
			</div>
			<div className="event-save-button" style={{ paddingTop: "20px" }}>
				<Button
					variant="contained"
					sx={{ backgroundColor: "#0055AF" }}
					onClick={() => handleSave()}>
					Save
				</Button>
			</div>
			{showMessage && (
				<SnackBar
					message={
						"There are dates within the next 18 Months without Reg / EPOC events created. Please create them and try saving again. Dates needing review: 10/11/2023, 10/12/2023, 10/13/2023, 10/14/2023."
					}
					variant={"error"}
					handleClose={() => handleMessageClose()}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					width={"613px"}
				/>
			)}
		</div>
	);
}
