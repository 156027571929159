import React, { useState } from "react";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@material-ui/core";

const CellPopOverImageField = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageInfo, setImageInfo] = useState(props?.node?.data);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");

  const onImageAction = (e) => {
    setOpen(!open);
    setAnchorEl(e.currentTarget);
    setPlacement(placement);
  };

  const handleImageClick = (e) => {
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    }
  };
  const handleClose = (e) => {
    setOpen(false);
    setAnchorEl(null);
  };

  return !props?.dynamicImage ? (
    <div
      style={{
        display: "flex",
        width: "40px",
      }}
    >
      {props.value && (
        <img
          width="100%"
          style={{ margin: 25, padding: 4 }}
          src={require(`../../../assets/images/assort/${props.value}.${
            props?.imgFormat || "png"
          }`)}
          alt=""
          onClick={onImageAction}
        />
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ width: 450 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <img
                width="100%"
                style={{ padding: "20px" }}
                src={require(`../../../assets/images/assort/${props.value}.${
                  props?.imgFormat || "png"
                }`)}
                alt=""
              />
              <table
                style={{
                  margin: 10,
                  alignItems: "center",
                }}
              >
                <tr>
                  <td width="50%">Style ID</td>
                  <td>{imageInfo.style_id}</td>
                </tr>
                <tr>
                  <td>Choice ID</td>
                  <td>{imageInfo.choice_id}</td>
                </tr>
                <tr>
                  <td>AIR</td>
                  <td>{imageInfo.air}</td>
                </tr>
                <tr>
                  <td>AIC</td>
                  <td>{imageInfo.air}</td>
                </tr>
                <tr>
                  <td>US Sales$</td>
                  <td>{imageInfo.us_sales_units}</td>
                </tr>
                <tr>
                  <td>US Buy Units</td>
                  <td>{imageInfo.us_buy_units}</td>
                </tr>
                <tr>
                  <td>US Receipt$</td>
                  <td>{imageInfo.us_receipt}</td>
                </tr>
                <tr>
                  <td>US Buy Units</td>
                  <td>{imageInfo.us_buy_units}</td>
                </tr>
                <tr>
                  <td>US GM$</td>
                  <td>{imageInfo.us_gm$}</td>
                </tr>
              </table>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  ) : (
    <div
      style={{
        // display: "block",
        margin: "0 auto",
        width: "100%",
        textAlign: "center",
      }}
    >
      {props.value && (
        <img
          width={props?.width || "auto"}
          height={props?.height || "50px"}
          src={props?.value}
          alt=""
          onClick={handleImageClick}
        />
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ width: 450 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <img
                  width="100%"
                  style={{ padding: "20px" }}
                  src={props?.value}
                  alt=""
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default CellPopOverImageField;
