import React from 'react'
import { Grid, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import SelectRenderer from "../../components/Select/SelectRenderer";
import CustomDatePicker from '../../components/CustomDatePicker';
import CheckboxGroup from '../../components/CheckboxGroup';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { createStyles, makeStyles } from "@mui/styles";


const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "15px",
        height: "15px"
      }
    },
  })
);
export const OptimizationConstraints = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div style={{ padding: '2px 20px' }}>
        <div style={{ fontWeight: '600', fontStyle: 'poppins', fontSize: '20px', marginBottom: '5px' }}>
          Optimization constraints
        </div>
        <div style={{ padding: '20px', display: 'flex' }}>
          <div style={{ flexBasis: '50%' }}>
   

                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                    Service level / safety stock
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>



                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      MOQ's
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>


                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Supplier Capacity
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>


                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Supplier Container capacity
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Warehouse capacity
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Casepack sizing
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

          </div>

          <div style={{ flexBasis: '50%' }}>
          <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Open to buy / budget
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Cost Tier: consolidate future SKU orders
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>



                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                    Cost Tier: consolidate multiple SKU orders
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>


                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                    Cost Tier: consolidate multiple channel / site order
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>


                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Container capacity
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: '15px' }}>
                  <Grid item xs={5} sm={5} md={5} sx={{marginTop:'6px'} }>
                    <span style={{ fontStyle: 'poppins', fontSize: '13px', color: '#1D1D1D'}}>
                      Recommended Expecting PO
                    </span>

                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <div style={{ display: 'flex' }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        defaultValue="end"
                        className={classes.smallRadioButton}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />

                        <FormControlLabel
                          value="Optional"
                          control={<Radio />}
                          label="Optional"
                          labelPlacement="end"
                          className={classes.smallRadioButton}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>

          </div>

        </div>




      </div>


    </React.Fragment>
  )
}
