import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

const CustomCard = (props) => {
  const { children, cardStyles, fullWidth, fullHeight } = props;

  return (
    <div
      style={props?.sx||{
        width: "100%",
        marginLeft: "10px",
      }}
      className="customCard"
    >
      <Card classes={{ root: cardStyles }}>{children}</Card>
    </div>
  );
};

CustomCard.propTypes = {
  children: PropTypes.node,
  cardStyles: PropTypes.string,
};

export default CustomCard;
