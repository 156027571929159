import React from "react";
import "./style.css"
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function DateTime({ data }) {
  const [value, setValue] = React.useState(dayjs(data.defaultValue));
  return (
    <div
      className="date-time-picker-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label
        style={{
          marginRight: "10px",
          fontSize: "12px",
          color: "#758490",
        }}
      >
        {data.label}
      </label>
      <LocalizationProvider
        sx={{ paddingTop: "0px !important" }}
        dateAdapter={AdapterDayjs}
      >
        <DateTimePicker
            className="date-time-picker"
            sx={{height:30, width:data.width}}
            slotProps={{ textField: { size: 'small' } }}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
      </LocalizationProvider>
    </div>
  );
}
