import { useState } from "react";
import PlaceholderImage from "../../../assets/placeholder.png";
const VisualDisplay = (props) => {
	return (
		<div>
			<div style={props.data.style}>
				<img
					width={props.data.imageWidth}
					height={props.data.imageHeight}
					src={require(`../../../assets/images/assort/${props.data.img}.png`)}
					alt=""
				/>
			</div>
		</div>
	);
};
export default VisualDisplay;
