import React, { useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import { Grid, InputAdornment, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@mui/styles";
import LockOpen from "@mui/icons-material/LockOpen";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomModal } from "./customModal";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import useAction from "../../../utils/useAction";
const styles = (theme) => ({
	inputLabel: {
		lineHeight: "1.5",
		color: "#8596a9",
		fontSize: "0.9rem",
		marginBottom: "0.2rem",
	},
	disabled: {
		// backgroundColor: "#C7E4FD !important",
		backgroundColor: "#e8e8e8 !important",
		color: "#C7E4FD !important",
	},
	refreshView: {
		backgroundColor: "#f07c7c !important",
	},
});
const useStyles = makeStyles(styles);

export const EditTextField = (props, obj) => {
	const [initialValue] = useState(props.value);
	const classes = useStyles();
	const dispatch = useDispatch();
	const invokeAction = useAction();
	const [updatedValue, setUpdatedValue] = useState({});
	const { selectedRowInfo, tableInfo, editActionInfo, dependentInfo } =
		useSelector((state) => state.home);
	const [open, setOpen] = useState(false);
	const [itemIndex, setItemIndex] = useState(null);
	const [valuesUpdatedObj, setValuesUpdatedObj] = useState({});
	const [disableEdit] = useState(() => {
		if (props.data && props.data.hasOwnProperty("#disable_edit_fields")) {
			const value = props.data["#disable_edit_fields"];
			if (!value) return false;
			return value.split(",").includes(props.colDef.field);
		}
	});
	const oldValue = useRef(null);
	const renderPass = useCallback(
		(props) => {
			if (props.data) {
				if (props.data['#disable'] === "Y") {
					//if row is greyed out
					return false
				}
				if (props.data.hasOwnProperty("#disable_edit_fields")) {
					if (disableEdit) return false;
				} else {
					const v = props.data?.[`#disable_edit_${props.colDef.field}`] || props.data?.['#disable_edit']
					if (v === 'Y') {
						return false
					}
				}
			}
			if (obj?.editDependence) {
				const checked = props.api.selectionService.selectedNodes[
					props.node.rowIndex
				]
					? true
					: false;
				return editActionInfo[obj?.actionKey] && checked;
			} else return true;
		},
		[disableEdit]
	);
	const handleInputChange = (e) => {
		oldValue.current = updatedValue?.value;
		const { label, value } = e.target;
		const updateObj = {
			currentRow: props.data,
			field: props.colDef.field,
			value: value,
			rowData: props.rowData,
			rowIndex: parseInt(props.data.id) - 1,
			isUpdated: true,
		};
		setUpdatedValue(updateObj);
	};
	useEffect(() => {
		if (props?.updateParentGrid && updatedValue.hasOwnProperty("value")) {
			const str = updatedValue.value;
			let sufix = "";
			if (props.sufix) {
				sufix = str.charAt(str.length - 1) == props.sufix ? "" : props.sufix;
			}
			props.setValue(str + sufix);
		}
	}, [updatedValue]);
	const handleOnBlur = () => {
		const { currentRow, value } = updatedValue;
		let updateParams = { ...valuesUpdatedObj };
		updateParams[`${updatedValue.field}`] = updatedValue?.isUpdated
			? true
			: false;
		setValuesUpdatedObj({ ...updateParams });
		let dependentParams = {};
		if (Object.keys(updatedValue).length > 0) {
			if (props?.parent_table_key) {
				let params = {};
				params[`${props.parent_table_key}`] = tableInfo[
					`${props.parent_table_key}`
				].filter((obj) => {
					if (props.data && parseInt(props.data.id) === parseInt(obj.id)) {
						obj[`${props.colDef.field}`] = value;
					}
					return obj;
				});
				dispatch({
					type: "TABLE_DATA",
					payload: params,
				});
			}

			if (currentRow["ref"] === "Placeholder1") {
				if (dependentInfo[`placeholder_dependent`]) {
					let placeholderParams = {};
					placeholderParams[`tb_plan_enable_pivot`] =
						tableInfo[`tb_plan_enable_pivot_placeholder_data`];
					dispatch({
						type: "TABLE_DATA",
						payload: placeholderParams,
					});
				} else {
					dependentParams[`placeholder_dependent`] = true;
					dispatch({
						type: "DEPENDENT_COMPONENTS",
						payload: dependentParams,
					});
				}
			}

			if (currentRow[`ref`] === "Placeholder2") {
				if (dependentInfo[`placeholder_dependent`]) {
					let placeholderParams = {};
					placeholderParams[`tb_plan_enable_pivot`] =
						tableInfo[`tb_plan_enable_pivot_placeholder_data`];
					dispatch({
						type: "TABLE_DATA",
						payload: placeholderParams,
					});
				} else {
					dependentParams[`placeholder_dependent`] = true;
					dispatch({
						type: "DEPENDENT_COMPONENTS",
						payload: dependentParams,
					});
				}
			}
			if (props.basedOnRowEdit) {
				let params = {};
				const updatedData = tableInfo[
					`${props.action.parent_table_key}`
				].filter((obj, index) => {
					if (updatedValue.rowIndex === index) {
						obj[`${props.colDef.field}`] = updatedValue.value;
						obj[`${props.colDef.field}_isValueUpdated`] = true;
					}
					return obj;
				});
				params[`${props.action.parent_table_key}`] = [...updatedData];

				dispatch({
					type: "TABLE_DATA",
					payload: params,
				});
			}
		}

		if (props.action) {
			if (props.action.actionName === "DATA_UPDATE_ON_CHANGE") {
				let params = {};
				params[`${props.action.parent_table_key}`] =
					tableInfo[`${props.action.source_table_key}`];

				dispatch({
					type: "TABLE_DATA",
					payload: params,
				});
			}
		}
	};

	const handleLockOption = (rowData) => {
		let params = {};
		// const metricData = tableInfo["tb_plan_department_locked_celldata"]
		let metricData = tableInfo[`${props.action.parent_table_key}`];
		switch (props.action.type) {
			case "ROW_LOCKING":
				metricData = metricData.filter((obj) => {
					if (obj.ref === rowData.ref) {
						obj[`${props.colDef.field}_locked`] = obj[
							`${props.colDef.field}_locked`
						]
							? false
							: true;
						obj[`${rowData.id}_locked`] = obj[`${rowData.id}_locked`]
							? false
							: true;
						return obj;
					}
					return obj;
				});
				params[`${props.action.parent_table_key}`] = metricData;
				dispatch({
					type: "TABLE_DATA",
					payload: params,
				});
				break;

			default:
				break;
		}
	};

	const handleEyeIcon = (action, index) => {
		if (action.actionName === "modal") {
			let params = {};
			params[`${props.value}`] = true;
			params[`active_store`] = props.value;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
			setOpen(!open);
			setItemIndex(index);
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	const generatePayload = (type, props) => {
		switch (type) {
			case "multi":
				const str = props?.data[`#multi_${props.colDef.field}`];
				return (str && str?.split(",")) || null;
			default:
				return null;
		}
	};
	const handleAction = (action, props) => {
		let u = updatedValue.value;
		let i = initialValue;
		if (i !== undefined) {
			u = parseFloat(u.replace(/,|%/g, ""));
			i = parseFloat(i.replace(/,|%/g, ""));
			if (u > i) {
				const payload = generatePayload(action?.onGreater?.payloadType, props);
				const p =
					action?.onGreater?.payloadType === "multi"
						? payload && payload[0]
						: payload;
				invokeAction(action?.onGreater, p);
			} else if (u < i) {
				const payload = generatePayload(action?.onLesser?.payloadType, props);
				const p =
					action?.onLesser?.payloadType === "multi"
						? payload && payload[1]
						: payload;
				invokeAction(action?.onLesser, p);
			} else {
				const payload = generatePayload(action?.onEqual?.payloadType, props);
				const p =
					action?.onEqual?.payloadType === "multi"
						? payload && payload[2]
						: payload;
				invokeAction(action?.onEqual, p);
			}
		}
	};
	const onKeyPress = (e) => {
		if (props?.setDependents || props.action || props.keyEvent) {
			if (
				e.key === "Enter" &&
				updatedValue.value !== undefined &&
				updatedValue.value !== oldValue.current
			) {
				oldValue.current = updatedValue.value;
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: props.setDependents,
				});
				if (props.action) {
					if (props.action?.default) {
						const dynamicPayload = props.action.default.dynamicPayload
							? props
							: null;
						invokeAction(props.action.default, dynamicPayload);
					}
					handleAction(props.action, props);
				}
			}
		}
	};

	return renderPass(props) ? (
		<>
			{open ? (
				<>
					<CustomModal
						show={open}
						{...props.action}
						handleClose={handleClose}
					/>
				</>
			) : null}

			{props.data ? (
				<>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									paddingTop: "10px",
								}}>
								{props["isEyeIconRequired"] &&
								props.data["isEyeIconRequired"] ? (
									<>
										<TextFieldWithLabel
											onKeyPress={onKeyPress}
											type="text"
											onChange={handleInputChange}
											defaultValue={props?.valueFormatted || props.value}
											// value={((props?.valueFormatted) || props.value )}
											handleOnBlur={handleOnBlur}
											// disabled={props.disabled}
											startAdornment={
												props.data.position === "start" && (
													<InputAdornment position="start">
														{props.data.symbol}
													</InputAdornment>
												)
											}
											endAdornment={
												props.data.position === "end" ? (
													<>
														<InputAdornment position="end">
															{props.data.symbol}

															{props["isEyeIconRequired"] ? (
																<>
																	{" "}
																	{props.colDef.field}
																	<EditIcon
																		sx={{ fontSize: 15, cursor: "pointer" }}
																		onClick={() => {
																			handleLockOption(props.data);
																		}}
																	/>
																</>
															) : null}
														</InputAdornment>
													</>
												) : (
													<>
														{props["isEyeIconRequired"] ? (
															<>
																<EditIcon
																	sx={{ fontSize: 15, cursor: "pointer" }}
																	onClick={() => {
																		if (props.action) {
																			handleEyeIcon(
																				props.action,
																				props.action.index
																			);
																		}
																	}}
																/>
															</>
														) : null}
													</>
												)
											}
											disabled={
												props.data[`${props.colDef.field}_locked`] ||
												props.data[`${props.data.id}_locked`] ||
												props.isDisabled ||
												(props.data && props.data["disabled"])
											}
											className={
												props.data[`${props.colDef.field}_locked`] ||
												props.data[`${props.data.id}_locked`] ||
												props.isDisabled ||
												(props.data && props.data["disabled"])
													? classes.disabled
													: ["Ulta Beauty", "Locks & Mane"].indexOf(
															props.data["ref"]
													  ) !== -1 &&
													  props.colDef.field === "feb_total" &&
													  props.data.hasOwnProperty("is_colour")
													? classes.refreshView
													: null
											}
											style={props.style}
										/>{" "}
										{dependentInfo[`mapped_store_${props.value}`] &&
										dependentInfo[`${props.value}`] &&
										selectedRowInfo[`${props.tableMappedKey}`] &&
										selectedRowInfo[`${props.tableMappedKey}`].length > 0 ? (
											<>
												&nbsp; Map to{" "}
												{
													selectedRowInfo[`${props.tableMappedKey}`][0][
														`${props.mappingKey}`
													]
												}
											</>
										) : null}
									</>
								) : (
									<>
										{!props["isEyeIconRequired"] ? (
											<>
												{props?.basedOnRowEdit ? (
													<>
														{props.data["isEditable"] ? (
															<>
																<TextFieldWithLabel
																	onKeyPress={onKeyPress}
																	type="text"
																	onChange={handleInputChange}
																	defaultValue={
																		props?.valueFormatted || props.value
																	}
																	// value={((props?.valueFormatted) || props.value )}
																	handleOnBlur={handleOnBlur}
																	startAdornment={
																		props.data.position === "start" && (
																			<InputAdornment position="start">
																				{props.data.symbol}
																			</InputAdornment>
																		)
																	}
																	endAdornment={
																		props.data.position === "end" ? (
																			<>
																				<InputAdornment position="end">
																					{props.data.symbol}
																					{props.isLockRequired ? (
																						<>
																							{props.data[
																								`${props.colDef.field}_locked`
																							] ? (
																								<LockIcon
																									sx={{
																										fontSize: 15,
																										cursor: "pointer",
																									}}
																									onClick={() => {
																										handleLockOption(
																											props.data
																										);
																									}}
																								/>
																							) : (
																								<>
																									<LockOpenIcon
																										sx={{
																											fontSize: 15,
																											cursor: "pointer",
																										}}
																										onClick={() => {
																											handleLockOption(
																												props.data
																											);
																										}}
																									/>
																								</>
																							)}
																						</>
																					) : null}
																				</InputAdornment>
																			</>
																		) : (
																			<>
																				{props.isLockRequired ? (
																					<>
																						{props.data[
																							`${props.colDef.field}_locked`
																						] ? (
																							<LockIcon
																								sx={{
																									fontSize: 15,
																									cursor: "pointer",
																								}}
																								onClick={() => {
																									handleLockOption(props.data);
																								}}
																							/>
																						) : (
																							<>
																								<LockOpenIcon
																									sx={{
																										fontSize: 15,
																										cursor: "pointer",
																									}}
																									onClick={() => {
																										handleLockOption(
																											props.data
																										);
																									}}
																								/>
																							</>
																						)}
																					</>
																				) : null}
																			</>
																		)
																	}
																	disabled={
																		props.data[
																			`${props.colDef.field}_locked`
																		] ||
																		props.data[`${props.data.id}_locked`] ||
																		props.isDisabled ||
																		(props.data && props.data["disabled"])
																	}
																	className={
																		props.data[
																			`${props.colDef.field}_locked`
																		] ||
																		props.data[`${props.data.id}_locked`] ||
																		props.isDisabled ||
																		(props.data && props.data["disabled"])
																			? classes.disabled
																			: ["Ulta Beauty", "Locks & Mane"].indexOf(
																					props.data["ref"]
																			  ) !== -1 &&
																			  props.colDef.field === "feb_total" &&
																			  props.data.hasOwnProperty("is_colour")
																			? classes.refreshView
																			: null
																	}
																	style={props.style}
																	backgroundColor={
																		valuesUpdatedObj[`${props.colDef.field}`] ||
																		props.data[
																			`${props.colDef.field}_isValueUpdated`
																		]
																			? "#F6C6C8"
																			: "white"
																	}
																/>
															</>
														) : (
															props.value
														)}
													</>
												) : (
													<>
														<TextFieldWithLabel
															onKeyPress={onKeyPress}
															type="text"
															onChange={handleInputChange}
															defaultValue={
																props?.valueFormatted || props.value
															}
															// value={((props?.valueFormatted) || props.value )}
															handleOnBlur={handleOnBlur}
															field={props.colDef.field}
															startAdornment={
																props.data.position === "start" && (
																	<InputAdornment position="start">
																		{props.data.symbol}
																	</InputAdornment>
																)
															}
															endAdornment={
																props.data.position === "end" ? (
																	<>
																		<InputAdornment position="end">
																			{props.data.symbol}
																			{props.isLockRequired ? (
																				<>
																					{props.data[
																						`${props.colDef.field}_locked`
																					] ? (
																						<LockIcon
																							sx={{
																								fontSize: 15,
																								cursor: "pointer",
																							}}
																							onClick={() => {
																								handleLockOption(props.data);
																							}}
																						/>
																					) : (
																						<>
																							<LockOpenIcon
																								sx={{
																									fontSize: 15,
																									cursor: "pointer",
																								}}
																								onClick={() => {
																									handleLockOption(props.data);
																								}}
																							/>
																						</>
																					)}
																				</>
																			) : null}
																		</InputAdornment>
																	</>
																) : (
																	<>
																		{props.isLockRequired ? (
																			<>
																				{props.data[
																					`${props.colDef.field}_locked`
																				] ? (
																					<LockIcon
																						sx={{
																							fontSize: 15,
																							cursor: "pointer",
																						}}
																						onClick={() => {
																							handleLockOption(props.data);
																						}}
																					/>
																				) : (
																					<>
																						<LockOpenIcon
																							sx={{
																								fontSize: 15,
																								cursor: "pointer",
																							}}
																							onClick={() => {
																								handleLockOption(props.data);
																							}}
																						/>
																					</>
																				)}
																			</>
																		) : null}
																	</>
																)
															}
															disabled={
																props.data[`${props.colDef.field}_locked`] ||
																props.data[`${props.data.id}_locked`] ||
																props?.isDisabled ||
																(props.data && props.data["disabled"])
															}
															className={
																props.data[`${props.colDef.field}_locked`] ||
																props.data[`${props.data.id}_locked`] ||
																props.isDisabled ||
																(props.data && props.data["disabled"])
																	? classes.disabled
																	: ["Ulta Beauty", "Locks & Mane"].indexOf(
																			props.data["ref"]
																	  ) !== -1 &&
																	  props.colDef.field === "feb_total" &&
																	  props.data.hasOwnProperty("is_colour")
																	? classes.refreshView
																	: null
															}
															style={props.style}
														/>
													</>
												)}
											</>
										) : (
											<>{props.data[`${props.mappingKey}`]}</>
										)}
									</>
								)}
							</div>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					{props["aggFunc"] ? (
						<> {props?.node?.aggData[`${props.mappingKey}`]} </>
					) : null}
					{/* {props.data && props.data[`${props.mappingKey}`]} {props.data.symbol} */}
				</>
			)}
		</>
	) : (
		props.value || ""
	);
};
