import React, { useEffect, useRef, useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Rnd } from "react-rnd";
import { getMultipleModelsData } from "../../pages/Home/action";
import DraggableCoreComponent from "./DraggableCoreComponent";
import { Diversity2Outlined } from "@mui/icons-material";

const DragRect = (props) => {
  // const { widthValue, heightValue, text, fill, x, y, fixed, setFreeze } = props;
  const { data, wrapperHeight, wrapperWidth, wrapperStyle } = props?.data?.info;
  const [planoGramData, setPalnoGramData] = useState(null);
  const [map, setMap] = useState();
  const [prediction, setPrediction] = useState(data || undefined);
  const [filled, setfilled] = useState(true);
  const [freezeAll, setFreezeAll] = useState(false);
  const [showStoreView, setShowStoreView] = useState(true);
  const [resumeRender, setResumeRender] = useState(false);
  // useEffect(() => {
  //   getMultipleModelsData({ model_names: ["react_floor_plan"] }).then((res) => {
  //     setPrediction(res.react_floor_plan);
  //   });
  // }, []);

  const onClick = (id) => {
    const data = filled ? { ...prediction } : { ...map };
    const newData = {
      ...data,
      [id]: { ...data[id], freezeAll: !data[id]["freezeAll"] },
    };
    console.log("🚀 ~ file: App.js ~ line 396 ~ onClick ~ newData", newData);
    if (!filled) {
      setMap(newData);
    } else {
      setPrediction(newData);
    }
  };
  useEffect(() => {
    if (!filled) {
      const data = { ...map };
      for (let key in data) {
        console.log("🚀 ~ file: App.js ~ line 396 ~ useEffect ~ key", key);

        data[key]["freezeAll"] = freezeAll;
      }
      setMap(data);
    } else {
      const data = { ...prediction };
      for (let key in data) {
        data[key]["freezeAll"] = freezeAll;
      }
      setPrediction(data);
    }
    setTimeout(()=>{
      setResumeRender(!resumeRender)
    },1500)
  }, [freezeAll]);

  const loadRnd = (value) => {
    return (
      <div className="container">
        <div className="App">
          <>
            <main
              style={{
                width: wrapperWidth || "100%",
                height: wrapperHeight || "700px",
                border: "solid 1px lightgray",
                ...wrapperStyle
              }     
              }
            >
              {resumeRender ? Object.entries(filled ? prediction : map).map(
                ([key, rectProps]) => (
                  <DraggableCoreComponent
                    key={key}
                    {...rectProps}
                    setFreeze={() => onClick(key)}
                  />
                )
              ) : <p style={{maringTop:"5%"}}>Loading....</p>}
            </main>
          </>
        </div>
      </div>
    );
  };

  // const style = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   border: "solid 1px #ddd",
  //   background: fill,
  // };
  return <>{prediction !== undefined && loadRnd()}</>;
};

export default DragRect;

const predictionData = {
  "B5Z9EmwCllPgfbdl8h2Lp-cTu": {
    type: "rect",
    freezeAll: true,
    widthValue: "20%",
    heightValue: 35.99999999999972,
    strokeWidth: 5,
    text: "ELFA",
    fill: "#7F00FF",
    stroke: "#000000",
    rotation: 0,
    x: 10.54,
    y: 70,
    fixed: true,
  },
  Rdf3ifcvTbhngvfgcdVfKeMF1u9Aef: {
    type: "rect",
    freezeAll: true,
    widthValue: "20%",
    heightValue: 35.99999999999972,
    fill: "#808080",
    stroke: "#000000",
    strokeWidth: 5,
    text: "Closet",
    x: 382,
    y: 70,
    fixed: true,
  },
  "4MJhyNAXQeasxdcfgSyh6f2J7vO3": {
    type: "rect",
    freezeAll: true,
    widthValue: "20%",
    heightValue: 35.99999999999972,
    fill: "#C576F6",

    stroke: "#000000",
    strokeWidth: 5,
    text: "Bath",
    x: 752,
    y: 70,
    fixed: true,
  },
  LK52QCQbthvgrcebMnN0We854: {
    type: "rect",
    freezeAll: true,
    widthValue: "20%",
    heightValue: 35.99999999999972,
    fill: "#D7A1F9",
    stroke: "#000000",
    strokeWidth: 5,
    text: "Box",
    x: 1120,
    y: 70,
    fixed: true,
  },

  CRoVeYl8ysadsfdgyj0vx7kJHxW5: {
    text: "Handbags",

    type: "rect",
    freezeAll: true,
    widthValue: 98.71310150610104,
    heightValue: 212.74509803921683,
    fill: "#c9aa88",
    stroke: "#000000",
    rotation: 0,
    x: 1.6434492469494348,
    y: 129.12745098039153,
  },
  "Wuw9SWDYghfffK74YAy-1hAr8": {
    text: "Skirts",

    type: "rect",
    freezeAll: true,
    widthValue: 99.67105263157882,
    heightValue: 277.45098039215725,
    fill: "#6c6c6c",
    stroke: "#000000",
    rotation: 0,
    x: 1.6644736842104138,
    y: 383.77450980391984,
  },
  JrBoM8UYZasdfgMMpBzH8tl5tm: {
    text: "Tops",

    type: "rect",
    freezeAll: true,
    widthValue: 138.157894736842,
    heightValue: 153.92156862745088,
    fill: "#5985b5",
    stroke: "#000000",
    rotation: 0,
    x: 190.92105263157873,
    y: 156.53921568627467,
  },
  fhgtSfqmWP8b7Pi87uytraPhRuz5: {
    text: "Tops",

    type: "rect",
    freezeAll: true,
    widthValue: 149.99999999999991,
    heightValue: 194.1176470588232,
    fill: "#5985b5",
    stroke: "#000000",
    rotation: 0,
    x: 188,
    y: 399.94117647058863,
  },
  B5Gchuh21KJjutyrsPmYH3OqYcV: {
    text: "Bill Counter",

    type: "rect",
    freezeAll: true,
    widthValue: 150,
    heightValue: 100,
    fill: "#ffffff",
    stroke: "#000000",
    rotation: 0,
    x: 503,
    y: 123,
  },
  kNPCsBuGYEYzqwaxescrtdvyfuI1vHPyoN5s: {
    text: "Clearance Items",

    type: "rect",
    freezeAll: true,
    widthValue: 226.97368421052613,
    heightValue: 151.96078431372487,
    fill: "#e4717a",
    stroke: "#000000",
    rotation: 0,
    x: 472.5131578947378,
    y: 317.5196078431372,
  },
  r_TutmkrJersfgth2lxfU2gsv1ab: {
    text: "Pants",

    type: "rect",
    freezeAll: true,
    widthValue: 149.99999999999957,
    heightValue: 343.13725490196043,
    fill: "#c3cde6",
    stroke: "#000000",
    rotation: 0,
    x: 780.9999999999976,
    y: 178.43137254901998,
  },
  "4711dOn11erfgttetI0lyWwQmkaZ8": {
    text: "UnderWear",

    type: "rect",
    freezeAll: true,
    widthValue: 137.17105263157902,
    heightValue: 85.41176470588266,
    fill: "#cec8ef",
    stroke: "#000000",
    rotation: 0,
    x: 1022.9144736842107,
    y: 108.7941176470585,
  },
  "9kZ-RvxregcNCgrlUh5SLacc": {
    text: "Stocking",

    type: "rect",
    freezeAll: true,
    widthValue: 135.20193628860093,
    heightValue: 132.30218956933672,
    fill: "#cec8ef",
    stroke: "#000000",
    rotation: 0,
    x: 1023.8990318556968,
    y: 206.34890521533117,
  },
  br9_MjHsdafesgrhTK90NNsP_Krzf: {
    text: "Casual Wear",

    type: "rect",
    freezeAll: true,
    widthValue: 136.18421052631572,
    heightValue: 119.60331582115465,
    fill: "#cec8ef",
    stroke: "#000000",
    rotation: 0,
    x: 1022.9078947368423,
    y: 368.1983420894225,
  },
  qtJZIUVGpvPeFTgrdhfbeKwE_UzO: {
    text: "Jeans",

    type: "rect",
    freezeAll: true,
    widthValue: 134.21052631578928,
    heightValue: 140.25263951734428,
    fill: "#cec8ef",
    stroke: "#000000",
    rotation: 0,
    x: 1027.8947368421054,
    y: 518.3736802413275,
  },
};
