import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Paper, Grid, Typography } from "@mui/material";
import Select from "./Select";
import Text from "../../utils/Text";
import { useDispatch, useSelector } from "react-redux";

const Item = styled(Paper)(({ theme, width }) => ({
	...theme.typography.body2,
	textAlign: "left",
	boxShadow: "none",
	background: "transparent",
	width,
	color: theme.palette.text.secondary,
}));

const SelectRenderer = (props) => {
	const {
		type,
		filterLabel,
		style,
		labelStyle,
		isMandatory,
		isMulti,
		placeholder,
		updateSelected,
		options,
		selectedItems,
		width,
		isDisabled,
		isIconRequired,
		labelType,
		labelWidth,
		filter_id,
	} = props;

	const dispatch = useDispatch();
	const { dropdownInfo, dropdownSelectionData } = useSelector(
		(state) => state.home,
	);
	const [optionsList, setOptionsList] = useState(options);
	const [allOptions] = useState(options);


	useEffect(() => {
		if (dropdownInfo[filter_id]) {
			const visibleOptions = new Set(dropdownInfo[filter_id]);
			if (
				dropdownSelectionData[filter_id] &&
				dropdownSelectionData[filter_id].length > 0
			) {
				const updatedSelection = [];
				const depedenciesToHide = {};
				dropdownSelectionData[filter_id].forEach((obj) => {
					if (visibleOptions.has(obj.id)) {
						updatedSelection.push(obj);
					} else {
						depedenciesToHide[obj.id] = false;
					}
				});
				dispatch({
					type: "GET_DROPDOWN_SELECTION_DATA",
					payload: {
						...dropdownSelectionData,
						[filter_id]: [...updatedSelection],
					},
				});
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: depedenciesToHide,
				});
			}
			setOptionsList(() => {
				return [...allOptions.filter((obj) => visibleOptions.has(obj.id))];
			});
		}
	}, [dropdownInfo]);

	useEffect(() => {
		if (props?.isStoreRequired) {
			if (!dropdownInfo[filter_id]) {
				dispatch({
					type: "DROPDOWN_INFO",
					payload: {
						[filter_id]: [
							...options
								.filter((obj) => !(obj?.default_hidden === "Y"))
								.map(({ id }) => id),
						],
					},
				});
			}
			if (!dropdownSelectionData[filter_id]) {
				dispatch({
					type: "GET_DROPDOWN_SELECTION_DATA",
					payload: {
						...dropdownSelectionData,
						[filter_id]: [
							...options.filter((obj) => obj?.is_default_value === "Y"),
						],
						[props.componentKey]: [
							...options.filter((obj) => obj?.is_default_value === "Y"),
						],
					},
				});
			}
		}		return () => {
			if (props?.clearDependentsOnDestroy) {
				const params = {};
				allOptions.forEach(({ id }) => {
					params[id] = false
					if (props?.componentKey) {
						params[`${props.componentKey}_${id}`] = false
					}
				});
				dispatch({
					type: "DEPENDENT_COMPONENTS",
					payload: params,
				});

				const p = {
					[filter_id]: null
				}
				if (props?.componentKey) {
					p[props.componentKey] = null
				}
				dispatch({
					type: "GET_DROPDOWN_SELECTION_DATA",
					payload: p
				});
			}
		};
	}, []);
	return (
		<div>
			{props.horizontalView ? (
				<Grid container>
					<Grid item xs={4} sm={4} md={4}>
						<Text
							style={{
								marginRight: "10px",
								fontSize: "12px",
								color: "#758490",
								minWidth: labelWidth ? labelWidth : "150px",
							}}
						>
							{filterLabel}
							{isMandatory ? <span style={{ color: "red" }}> * </span> : null}
						</Text>
					</Grid>

					<Grid item xs={8} sm={8} md={8}>
						<Select
							type={type}
							placeholder={placeholder}
							isMulti={isMulti}
							initialData={options}
							selectedOptions={selectedItems}
							reset={false}
							dependency={selectedItems}
							updateSelected={updateSelected}
							onDropdownOpen={() => console.log("Ok")}
							forceApiCall={false}
							isDisabled={isDisabled}
							isIconRequired={isIconRequired}
							width={width}
						/>
					</Grid>
				</Grid>
			) : props.settings ? (
                <div style={{ display: labelType, alignItems: "center" }}>
                    <Text
                        style={{
                            marginRight: "10px",
                            fontSize: "12px",
                            color: "#758490",
                            minWidth: labelWidth ? labelWidth : "150px",
                        }}
                    >
                        {filterLabel}
                        {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
                    </Text>
                    <Select
                        type={type}
                        placeholder={placeholder}
                        isMulti={isMulti}
                        initialData={props?.isStoreRequired ? optionsList : options}
                        selectedOptions={selectedItems}
                        reset={false}
                        dependency={selectedItems}
                        updateSelected={updateSelected}
                        onDropdownOpen={() => console.log("Ok")}
                        forceApiCall={false}
                        isDisabled={isDisabled}
                        isIconRequired={isIconRequired}
                        width={width}
                        {...props}
                    />
                </div>
                ) : (
				<div style={{ display: labelType, alignItems: "center" }}>
					<Text
						style={{
							marginRight: "10px",
							fontSize: "12px",
							color: "#758490",
							minWidth: labelWidth ? labelWidth : "150px",
							...labelStyle
						}}
					>
						{filterLabel}
						{isMandatory ? <span style={{ color: "red" }}> * </span> : null}
					</Text>
					<Select
						type={type}
						placeholder={placeholder}
						isMulti={isMulti}
						initialData={props?.isStoreRequired ? optionsList : options}
						selectedOptions={selectedItems}
						reset={false}
						dependency={selectedItems}
						updateSelected={updateSelected}
						onDropdownOpen={() => console.log("Ok")}
						forceApiCall={false}
						isDisabled={isDisabled}
						isIconRequired={isIconRequired}
						width={width}
						{...props}
					/>
				</div>
			)}
		</div>
	);
};

export default SelectRenderer;
