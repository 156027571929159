import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Table } from "../../../components/Table";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";

const SplitTableGeneric = ({ data }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([{ label: "B1", value: "B1" }]);
  const [options, setOptions] = useState([
    { label: "A1", value: "A1" },
    { label: "A2", value: "A2" },
    { label: "A3", value: "A3" },
    { label: "B1", value: "B1" },
    { label: "B2", value: "B2" },
    { label: "B3", value: "B3" },
  ]);
  const {
    apiData,
    dependentInfo,
    tableInfo,
    selectedRowInfo,
    formEditedValues,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (formEditedValues[`split-table`]) {
      let reqParmas = {};
      const gradeCluster = tableInfo[`tb_grade_cluster_breakdown`];
      reqParmas[`${data["addTable"].key}`] = gradeCluster.filter(
        (obj) => obj.cluster === formEditedValues[`split-table`]
      );
      reqParmas[data["removeTable"].key] = gradeCluster.filter(
        (obj) => obj.cluster === value[0].label
      );
      dispatch({
        type: "TABLE_DATA",
        payload: reqParmas,
      });
    }
  }, []);

  const [leftTableLength, setleft] = useState(data.allItems);
  const [rightTableLength, setRight] = useState(data.currentItems);

  const addToRemove = () => {
    // tb_item_group_allitem to tb_item_group_management
    const storeList = tableInfo[`${data["removeTable"].key}`] || [];
    const selectedData = selectedRowInfo[`${data["addTable"].key}`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list_next = tableInfo[`${data["addTable"].key}`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );

    let reqParmas = {};
    reqParmas[`${data["removeTable"].key}`] = [...storeList, ...selectedData];
    let lenLeft = [...storeList, ...selectedData];
    setleft(lenLeft.length);

    reqParmas[data["addTable"].key] = [...store_list_next];
    let lenRight = [...store_list_next];
    setRight(lenRight.length);

    let selectedRowParams = {};
    selectedRowParams[`${data["removeTable"].key}`] = [];
    selectedRowParams[data["addTable"].key] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  const removeToAdd = () => {
    // tb_item_group_management to tb_item_group_allitem
    const store_list_next = tableInfo[`${data["addTable"].key}`] || [];
    const selectedData = selectedRowInfo[`${data["removeTable"].key}`];
    const storeIds = selectedData.map((obj) => obj.store_code);
    const store_list = tableInfo[`${data["removeTable"].key}`].filter(
      (obj) => storeIds.indexOf(obj.store_code) === -1
    );
    let reqParmas = {};
    reqParmas[`${data["addTable"].key}`] = [
      ...store_list_next,
      ...selectedData,
    ];
    let lenRight = [...store_list_next, ...selectedData];
    setRight(lenRight.length);

    reqParmas[data["removeTable"].key] = [...store_list];
    let lenLeft = [...store_list];
    setleft(lenLeft.length);

    let selectedRowParams = {};
    selectedRowParams[`${data["removeTable"].key}`] = [];
    selectedRowParams[data["addTable"].key] = [];
    dispatch({
      type: "TABLE_DATA",
      payload: reqParmas,
    });
    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowParams,
    });
  };
  return (
    <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <>
        <Grid item xs={5.7}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6subtitle2">
              {
                data.addTable.label ? <h4>{data.addTable.label}</h4>
                : 
                <h4
                style={{ color: "#666666" }}
              >{`All items: ${rightTableLength}`}</h4>
              }
             
            </Typography>
          </div>

          <Table
            apikey={data["addTable"].key}
            isStoreRequired={true}
            isApiNotRequird={false}
            isClickBasedOnParent={true}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={data["addTable"].info[0].column}
            floatingFilter={false}
            // height={"450px"}
            rowSelection={"multiple"}
          />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                textTransform: "none",
                margin: 5,
                // backgroundColor: "#0055AF",
                backgroundColor:data.addTable.buttonBackgroundColor?data.addTable.buttonBackgroundColor:"#0055AF",
                color: "white",
              }}
              variant={""}
              size={""}
              onClick={addToRemove}
            >
              {"Add"}
            </Button>
          </div>
        </Grid>
        <Typography sx={{ marginLeft: "10px" }}>{">>"}</Typography>
        <Grid item xs={5.7}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6subtitle2">
              {
                data.removeTable.label ? <h4>{data.removeTable.label}</h4>:<h4
                style={{ color: "#666666" }}
              >{`Current No of items :${leftTableLength}  `}</h4>
              }
              
            </Typography>
          </div>

          <Table
            apikey={data["removeTable"].key}
            isStoreRequired={true}
            isClickBasedOnParent={true}
            isApiNotRequird={false}
            tableCssStyle={"sizeColumnsToFit"}
            columnDefs={data["removeTable"].info[0].column}
            floatingFilter={false}
            // height={"450px"}
            rowSelection={"multiple"}
          />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{
                textTransform: "none",
                margin: 5,
                // backgroundColor: "#0055AF",
                backgroundColor:data.removeTable.buttonBackgroundColor?data.removeTable.buttonBackgroundColor:"#0055AF",
                color:data.removeTable.buttonColor?data.removeTable.buttonColor:"white",
                border:data.removeTable.buttonBorder?data.removeTable.buttonBorder:""
              }}
              variant={""}
              size={""}
              onClick={removeToAdd}
            >
              {"Remove"}
            </Button>
          </div>
        </Grid>
      </>
    </Grid>
  );
};
export default SplitTableGeneric;
