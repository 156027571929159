import React from "react";

export default function XDView(props) {
    const { data } = props
  return (
    <div>
      <div className= "page-content">
      <iframe width="1440" height="800" src={data.url} frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  );
}
