import moment from 'moment';
import { nrfWksPatternForAYr, qtrWkMapping } from './constants';

export function genMoWkMapping(fstMoOfYr = 1, wksCnt) {
  const moWkMapping = [];

  if (wksCnt === 53) {
    nrfWksPatternForAYr[0] = 5;
  }

  let wkCounter = 0;
  for (let mo = fstMoOfYr; mo < nrfWksPatternForAYr.length + fstMoOfYr; mo++) {
    const moIdx = moment().month(mo).month();
    const noOfWks = nrfWksPatternForAYr[moIdx];
    const wks = [];
    for (let wk = 1; wk <= noOfWks; wk++) {
      wks.push(wkCounter + wk);
    }
    wkCounter += noOfWks;
    moWkMapping.push(wks);
  }
  nrfWksPatternForAYr[0] = 4;

  return moWkMapping;
}

export function genQtrWkMapping(wksCnt) {
  const fourthQtr = 3;
  const fiftyThreeWks = 53;
  const fiftyThirdWk = 53;
  const qtr4Len = qtrWkMapping[fourthQtr].length;
  const lstWK = qtrWkMapping[fourthQtr][qtr4Len - 1];
  const doesQ4HaveWk53 = lstWK === fiftyThirdWk;
  if (wksCnt === fiftyThreeWks) {
    if (!doesQ4HaveWk53) {
      qtrWkMapping[fourthQtr].push(53);
    }
  } else if (doesQ4HaveWk53) {
    qtrWkMapping[fourthQtr].pop();
  }
  return qtrWkMapping;
}

export function getDtsFrmWK(yr, wkNo) {
  const wkStDt = moment().year(yr).weeks(wkNo).startOf('week');
  const dtsInAWk = [];
  for (let i = 0; i < 7; i++) {
    const dt = moment(wkStDt).add(i, 'day');
    dtsInAWk.push(dt);
  }
  return dtsInAWk;
}

export function getCurrQtrFrmWK(qtrWeekMapping, wkNo) {
  let qtrNo = 0;
  qtrWeekMapping.forEach((qtr, idx) => {
    if (qtr.includes(wkNo)) {
      qtrNo = idx;
    }
  });
  return qtrNo;
}

export function getCurrMoFrmWK(moWkMapping, wkNo) {
  let qtrNo = 0;
  moWkMapping.forEach((qtr, idx) => {
    if (qtr.includes(wkNo)) {
      qtrNo = idx;
    }
  });
  return qtrNo;
}

export function getWksCnt(yr) {
  return moment().year(yr).weeksInYear();
}
export function getWkStDt(yr, wkNo) {
  return moment().year(yr).weeks(wkNo).startOf('week');
}
export function getWkEdDt(yr, wkNo) {
  return moment().year(yr).weeks(wkNo).endOf('week');
}

export function filterEventsforDisplay({ eventList, calStDt, calEnDt }) {
  return eventList?.filter((event) => {
    const { start_date: eventStDt, end_date: eventEnDt } = event;
    const isCampaignInBetweenCalendar =
      moment(eventStDt).isBetween(calStDt, calEnDt, null, '[]') ||
      moment(eventEnDt).isBetween(calStDt, calEnDt, null, '[]');
    const isCalendarInBetweenCampaign =
      moment(calStDt).isBetween(eventStDt, eventEnDt, null, '[]') &&
      moment(calEnDt).isBetween(eventStDt, eventEnDt, null, '[]');
    return isCampaignInBetweenCalendar || isCalendarInBetweenCampaign;
  });
}

export function getEventLeftSpace({ eventStDt, calStDt, noOfDaysInCal }) {
  // difference between event start date and calendar start date
  const diff = moment(eventStDt).diff(calStDt, 'days');
  const leftSpace = (diff / noOfDaysInCal) * 100;
  return leftSpace;
}

export function getEventTitleLeftSpace({ eventStDt, calStDt, noOfDaysInCal }) {
  // difference between event start date and calendar start date
  const diff = moment(eventStDt).diff(calStDt, 'days');
  let leftSpace;
  if (diff <= 0) {
    leftSpace = 0;
  } else {
    leftSpace = (diff / noOfDaysInCal) * 100;
  }
  return leftSpace;
}

export function getEventWidth({ eventStDt, eventEnDt, noOfDaysInCal }) {
  // difference between event start date and event end date
  const diff = moment(eventEnDt).diff(eventStDt, 'days') + 1;
  const widthInDays = diff;
  const width = (widthInDays * 100) / noOfDaysInCal;
  return width;
}
export function getEventTitleWidth({ eventStDt, eventEnDt, calStDt, calEnDt, noOfDaysInCal }) {
  // difference between event start date and event end date
  const diff = moment(eventEnDt).diff(eventStDt, 'days') + 1;
  // difference between calendar start date and event start date
  const diff1 = moment(eventStDt).diff(calStDt, 'days');
  // difference between calendar end date and event end date
  const diff2 = moment(eventEnDt).diff(calEnDt, 'days') + 1;

  let widthInDays = diff;
  if (diff1 < 0) {
    widthInDays = diff + diff1;
  } else if (diff2 > 0) {
    widthInDays = diff - diff2;
  }
  if (diff1 < 0 && diff2 > 0) {
    widthInDays = noOfDaysInCal;
  }
  const width = (widthInDays * 100) / noOfDaysInCal;
  return width;
}

export function getEventStatus({ start_date: eventStDt, end_date: eventEnDt }) {
  let status;
  const currDt = moment();
  if (currDt.isBetween(eventStDt, eventEnDt, null, '[]')) {
    status = 'ongoing';
  } else if (currDt.isAfter(eventEnDt)) {
    status = 'completed';
  } else if (currDt.isBefore(eventStDt)) {
    status = 'upcoming';
  }
  return status;
}
