import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";
import ComponentResolver from "../Resolver/ComponentResolver";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%", padding: 10 }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const VerticalTabs = (props) => {
  const { data } = props;
  const [value, setValue] = React.useState(data.activeTabIndex || 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
      }}
      style={data.style}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {data.tabs?.map((element, index) => (
          <Tab
            label={element?.label}
            sx={{
              alignItems: "flex-start",
              textTransform: "none",
              ...data?.tabLabelStyle,
            }}
            disabled={element?.disable}
          />
        ))}
      </Tabs>
      {data.tabs?.map((element, index) => (
        <TabPanel value={value} index={index}>
          <ComponentResolver pageObject={{ components: element.components }} />
        </TabPanel>
      ))}
    </Box>
  );
};
export default VerticalTabs;
