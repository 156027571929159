import React from "react";
import { Table } from "../../Table";

export default function CalendarSidePanel({ data }) {
  return (
    <div className="calendar-side-panel">
      <div className="calendar-side-panel-header">Events</div>
      <div className="calendar-side-panel-events">
        {data.map((item, index) => {
          return (
            <div className="events-panel" key={index}>
              <div className="events-panel-parent">{item.parent}</div>
              {item.children && (
                <div className="events-panel-child">
                  {item.children.map((child, index) => {
                    return (
                      <div className="events-panel-children" key={index}>
                        {child == ''? <span>&nbsp;</span> : child}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
