import React from "react";
import styles from "./index.module.scss";
import { Inventory2Outlined } from "@mui/icons-material";
import { Grid } from "@mui/material";

export default function CustomReportKpiCard(props) {
  const { data} = props;
  return (
    <Grid container spacing={0}>
      {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}> */}
      <div
        className={`${styles["kpi-container"]} ${styles["flex-column"]}`}
        style={{ marginLeft: 0 }}
      >
        <div className={styles["flex-align-center"]}>
          <div className={styles["kpi-icon-container"]}>
            <Inventory2Outlined style={{ color: "#758398" }} />
          </div>
          <div className={styles["kpi-title"]}>{data.header}</div>
        </div>
        <div
          className={`${styles["kpi-details-container"]} ${styles["flex-align-end"]} ${styles["flex-justify-space-between"]}`}
        >
          <div className={`${styles["unit-wrapper"]} ${styles["flex"]}`}>
            
              <div
                className={`${styles["unit-container"]} ${styles["flex-column"]}`}
              >
                <div className={styles["unit-value"]} style={{color:"#3792cb"}}>{data.value}</div>
              </div>
          </div>
        </div>
      </div>
      {/* </Grid> */}
    </Grid>
  )
}
