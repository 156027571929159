export const ColumnChartOptions = (props) => {
  const type = props.chartType === "column-percent" ? "column" : "bar";

  return {
    chart: {
      type,
      height: props.height,
    },
    title: {
      text: props.title,
    },
    xAxis: {
      categories: props.xaxis.categories,
    },
    yAxis: {
      title: {
        text: props.yaxis?.title || "Values",
      },
    },
    plotOptions: {
      series: {
        stacking: props.stacking,
      },
      //Changes for the custom colors -start
      column: {
        colorByPoint: props?.customColors?.show || false,
      },
    },
    colors: props?.customColors?.colors,
    //Changes for the custom colors -end

    //Extra part for a label on xAxis
    legend: {
      enabled: props?.showLegend || false,
    },

    series: props.data,
    credits: {
      enabled: false,
    },
  };
};

export const scatterChartOptions = (props) => {
  return {
    chart: {
      type: "scatter",
      zoomType: "xy",
      height: props.height,
    },
    title: {
      text: props.title,
    },
    // subtitle: {
    //     text: 'Source: Heinz  2003'
    // },
    xAxis: {
      title: {
        enabled: true,
        text: props.xaxis.title,
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      categories: props.xaxis.categories,
    },
    yAxis: {
      title: {
        text: props.yaxis.title,
      },
    },
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "top",
      x: 100,
      y: 70,
      floating: true,
      borderWidth: 1,
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        // states: {
        //     hover: {
        //         marker: {
        //             enabled: false
        //         }
        //     }
        // },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x} cm, {point.y} kg",
        },
      },
    },
    series: props.data,
    credits: {
      enabled: false,
    },
  };
};
