import { useEffect, useState } from "react";

const insightsSuggestions = [
  "How many units are allocated in North region?",
  "What are the top 5 stores having the most number of allocated units in North region?",
  "What is the region wise split of allocated units?",
  "Which product and store has been allocated maximum units?",
  "How many stores are there with Total Allocated Quantity more than 180?",
  "List of store where Total Allocated Quantity are more than 180?",
  "Give me district wise data of total allocated units.",
  "Which district has the highest number of units allocated?",
  "Give me the list of products having constrained allocation.",
  "Is there any product - store combination where Min and Total Allocated Qty are equal?",
  "Give me the list of stores having constrained allocation for SKU 185301.",
  "Give me the list of stores having constrained allocation for SKU 185301.",
  "Give me the list of stores having a constrained allocation in North region.",
  "List of stores having constrained allocation with their respective states",
  "Give me the list of products having a constrained allocation in MD state.",
  "How many stores are having a constrained allocation in store band 2?",
  "List of stores are having a constrained allocation in store band 2?",
  "Give me top 3 store from MD state which has received highest number of allocation.",
];

const generalNavSuggestions = [
  "When I am changing constraints during manual allocation, will it change permanently?",
  "How to create a manual allocation?",
  "How to change constraints during manual allocation?",
  "How to Update a Product Group?",
  "How to Update a Store Group?",
  "How to Create a Store Group?",
  "How to Create a Product Group?",
  "What is definition based Product Group?",
];

const battiesPlusSuggestions = [
  "Suggest me top 5 products that franchise needs to analyse?",
  "Suggest me top 5 Chargers products that franchise needs to focus more on selling?",
  "Suggest me top 5 products that for transfering store A to Store B."
]

const AutoComplete = ({ props }) => {
  const [filteredList, setFilteredList] = useState(false);

  useEffect(() => {
    {
      let tempArray = [];
      if (props?.responseKey === "batteries_plus_gen_inv_sm") {
        battiesPlusSuggestions.map((element, i) => {
          if (
            props?.showSuggestions &&
            element
              ?.toLowerCase()
              .includes(props?.showSuggestions?.toLowerCase())
          ) {
            tempArray.push(element);
          }
        });
      } else if (props?.activeFlow === "general_navigation") {
        generalNavSuggestions.map((element, i) => {
          if (
            props?.showSuggestions &&
            element
              ?.toLowerCase()
              .includes(props?.showSuggestions?.toLowerCase())
          ) {
            tempArray.push(element);
          }
        });
      } else{
        insightsSuggestions.map((element, i) => {
          if (
            props?.showSuggestions &&
            element
              ?.toLowerCase()
              .includes(props?.showSuggestions?.toLowerCase())
          ) {
            tempArray.push(element);
          }
        });
      }
      setFilteredList(tempArray);
    }
  }, [props?.showSuggestions]);
  return (
    <div className="suggestion-container">
      {filteredList?.length > 0 &&
        filteredList?.slice(0, 5)?.map((element) => {
          return (
            <div
              className="suggestion-item"
              onClick={() => {
                if (
                  props?.inputfieldRef !== undefined &&
                  props?.inputfieldRef !== null
                ) {
                  props.inputfieldRef.current.value = `${element}`;
                  props?.setShowSuggestions(false);
                }
              }}
            >
              {element}
            </div>
          );
        })}
    </div>
  );
};

export default AutoComplete;
