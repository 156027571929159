import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

const useStyles = makeStyles({
  summaryContainer: {
    border: `1px solid lightgray`,
    margin: `1rem 0`,
    padding: `0.5rem`,
    borderRadius: 10,
    fontSize: "12px",
    backgroundColor: "white",
  },
  summaryChip: {
    margin: "0 0.1rem",
  },
  dimension: {
    margin: "0px 20px 0px 10px",
  },
});
const FiltersSummary = ({
  filtersSummary,
  selectedDropdownValues,
  updateChips,
}) => {
  const classes = useStyles();

  const handleDelete = (itemIndex, dimension) => {
    console.info("You clicked the delete icon.", itemIndex, dimension);
    const params = { ...filtersSummary };
    const arr = params[dimension].section.filter(
      (_, index) => index != itemIndex
    );
    if (arr.length > 0) {
      params[dimension].section = arr;
    } else {
      delete params[dimension];
    }
    updateChips(params);
  };

  const getChips = (dimension) => {
    if (
      filtersSummary["keys"] &&
      filtersSummary["keys"].length > 0 &&
      Object.keys(selectedDropdownValues).length > 0
    ) {
      return (
        <>
          <span className={classes.dimension}>
            {filtersSummary["keys"].map((obj) => (
              <>
                {obj}
                {selectedDropdownValues[`${obj}`] &&
                  selectedDropdownValues[`${obj}`].map((item) => (
                    <Chip
                      className={classes.summaryChip}
                      label={item.value}
                      onDelete={() => handleDelete(item)}
                    />
                  ))}
              </>
            ))}
          </span>
        </>
      );
    } else if (filtersSummary[dimension].label) {
      return (
        <span className={classes.dimension}>
          {filtersSummary[dimension].label}
          {filtersSummary[dimension].section.map((item, index) => (
            <Chip
              className={classes.summaryChip}
              label={item}
              onDelete={() => handleDelete(index, dimension)}
            />
          ))}
        </span>
      );
    }
  };

  return (
    <>
      {selectedDropdownValues &&
      Object.keys(selectedDropdownValues).length == 0 ? null : (
        <div className={classes.summaryContainer}>
          {Object.keys(filtersSummary).map((dimension) => getChips(dimension))}
        </div>
      )}
    </>
  );
};

export default FiltersSummary;
