import React, { useState } from "react";
import { FileSelector } from "impact-ui";
import useAction from "../../utils/useAction";

export default function FileUploader(props) {
  const { data } = props;
  const [files, setFiles] = useState({});
  const invokeAction = useAction();

  const handleUploadFiles = () => {
    setFiles((prev) => {
      const obj = { ...prev };
      Object.keys(obj).forEach((key) => {
        if (!obj[key].isUploadStarted) {
          obj[key] = {
            ...obj[key],
            progress: 100,
            isUploadStarted: true,
          };
        }
      });
      return obj;
    });
    setTimeout(() => {
      invokeAction(data.action);
    }, 0);
  };

  //console.log("files", files);

  //   const handleCancelUpload = (key) => {
  //     files[key].abortController.abort();
  //     setFiles((prevFiles) => {
  //       const nextFiles = { ...prevFiles };
  //       delete nextFiles[key];
  //       return nextFiles;
  //     });
  //   };

  //   const handleCancelAllUploads = () => {
  //     Object.values(files).map((file) => {
  //       file.abortController.abort();
  //     });
  //   };

  return (
    <>
      <FileSelector
        files={files}
        setFiles={setFiles}
        onFileUpload={handleUploadFiles} //upload  button onClick
      />
    </>
  );
}
