import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import TagChip from "./TagChip";
import { CustomRendererComponent } from "../CustomRendererComponent";
import CellCheck from "../CellCheck";
export default function CellInfoTag(params) {
  const { tableFilters } = useSelector((state) => state.filter);
  const { size, info } = params;
  const dispatch = useDispatch();
  const tableFilterInfo = useMemo(() => {
    return tableFilters[params.parentTableKey] || {};
  }, [tableFilters, params]);

  function getValue(params) {
    const field = `${params.colDef.field}#tag`;
    let value = "";
    if (params.node.group) {
      value =
        params.colDef.cellRenderer === "CustomGroupCellRenderer"
          ? params.node.allLeafChildren[0].data[field]
          : "";
    } else {
      value = params.data[field];
    }
    return value;
  }
  const tagInfo = useMemo(() => {
    const value = getValue(params);
    if (value) {
      const [tag, color = "#a9a9a9"] = value.split(",");
      return {
        value: tag,
        color,
      };
    }
    return null;
  }, [params]);

  const handleClick = useCallback(
    (e) => {
      const column = `${params.colDef.field}#tag`;
      const tagExist =
        tableFilterInfo.tag && tableFilterInfo.tag[0]?.value === tagInfo.value;
      dispatch({
        type: "UPDATE_TABLE_FILTERS",
        payload: {
          [params.parentTableKey]: {
            //we could spread the tag but now lets assume filtering will be based on only single tag
            ...tableFilterInfo,
            tag: tagExist
              ? []
              : [
                  {
                    column,
                    value: tagInfo.value,
                  },
                ],
          },
        },
      });
    },
    [params, tagInfo, tableFilterInfo, dispatch]
  );

  const tagRenderer = useCallback(
    ({ value, color }) => {
      return (
        <TagChip
          label={value}
          color={color}
          handleClick={handleClick}
          size={size}
          info={info}
        />
      );
    },
    [handleClick, size]
  );

  function valueRenderer(params) {
    const t = params.rendererType;
    switch (t) {
      case "customRenderer":
        if (params.node.group) {
          return params.colDef.cellRenderer === "CustomGroupCellRenderer" ? (
            <CustomRendererComponent {...params} />
          ) : null;
        }
        return <CustomRendererComponent {...params} />;
      case "cellCheck":
        if (params.node.group) {
          return null
        }
        return <CellCheck {...params} />  
      default: {
        let value = "";
        if (params.node.group) {
          value =
            params.colDef.cellRenderer === "CustomGroupCellRenderer"
              ? groupCellRenderer(params)
              : "";
        } else {
          value = params.value;
        }
        return <div>{value}</div>;
      }
    }
  }

  return (
    <div className="cell-info-tag-container">
      {valueRenderer(params)}
      {tagInfo && tagRenderer(tagInfo)}
    </div>
  );
}
function groupCellRenderer(params) {
  const children = params.node.allLeafChildren;
  let value = children[0].data[params.colDef.field];
  if (params.colDef.aggFunc) {
    value = params.node.aggData[params.colDef.field];
  }
  return value;
}