import React from "react";
import { Grid, Card } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const CustomTestSmartCard = (props) => {
  return (
    <Grid container spacing={-5}>
      <div>
        {props.data.data.map((elem) => {
          return (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Card
                    style={{
                      backgroundColor: elem.color,
                      fontSize: "15px",
                      color: "GrayText",
                      width: elem.width,
                      height: elem.height,
                      border: "0.5px solid black",
                      // borderRadius: "8px",
                    }}
                  >
                    <h6
                      style={{
                        textAlign: "top",
                        marginTop: "8px",
                        marginLeft: "10px",
                        fontSize: "15px",
                        fontWeight: "bolder",
                        color: "GrayText",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginBottom: "0",
                      }}
                    >
                      {elem.header}
                    </h6>
                    <div
                      className="main"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "0",
                        padding: "0",
                      }}
                    >
                      {elem.data.map((item) => {
                        return (
                          <div
                            className="containter_div"
                            style={{
                              borderRadius: "8px",
                              margin: "40px",
                              marginTop: "15px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              className="icon_div"
                              style={{
                                // marginTop: "5px",
                                margin: "9px",
                              }}
                            >
                              {item.icon === "Completed" && (
                                <TaskAltOutlinedIcon
                                  fontSize="medium"
                                  color="primary"
                                />
                              )}
                              {item.icon === "Ongoing" && (
                                <AccessTimeIcon
                                  fontSize="medium"
                                  sx={{ color: "orange" }}
                                />
                              )}
                              {item.icon === "Planned" && (
                                <CalendarTodayOutlinedIcon
                                  fontSize="medium"
                                  sx={{ color: "pink" }}
                                />
                              )}
                            </div>

                            <div
                              className="value_div"
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  color: "black",
                                  marginTop: "1px",
                                  marginLeft: "1px",
                                  marginRight: "8px",
                                }}
                              >
                                {item.value}
                              </p>
                              <p
                                style={{
                                  fontWeight: "lighter",
                                  color: "GrayText",
                                  marginTop: "-18px",
                                  marginLeft: "1px",
                                  marginRight: "8px",
                                }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    </Grid>
  );
};

export default CustomTestSmartCard;
