import { useEffect, useState } from "react";
import useAction from "../../../../utils/useAction";
import { getFormattedValue } from "../../../../components/Table/utils";

function getValue(params) {
  let value = "";
  if (params.node.group) {
    value =
      params.colDef.cellRenderer === "CustomGroupCellRenderer"
        ? params.node.allLeafChildren[0].data[params.colDef.field]
        : "";
  } else {
    value = params.getValue();
  }
  return value;
}
export default function CustomCellValueRenderer(params) {
  const invokeAction = useAction();
  const value = getValue(params);

  function formatValue(value) {
    if (params.colDef.valueFormatterType) {
      return getFormattedValue(params.colDef.valueFormatterType, value);
    }
    return value;
  }
  useEffect(() => {
    invokeAction(params?.actionOnChange, params.node);
      if (params.refreshGrid) {
      }
  }, [value]);
  return <div>{formatValue(value)}</div>;
}
