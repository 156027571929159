import { postApiCall } from "../../../map/action";
import callApi from "../../../utils/callApi";

const getTreeLevelView = (data, id) => {};

export const fetchHierarchyData = (filterProperty, reqObj) => {
	let url = encodeURIComponent(filterProperty);
	let newData  = []
	if(reqObj['is_dependent'] === "TRUE"){
		newData = reqObj.data.filter(obj=> filterProperty.map(filerObj=> filerObj.id ).indexOf(obj.parent_id)!==-1 && obj.parent_type_id === reqObj.categoryid )
	 }else{
		newData = reqObj.data.filter(obj=> obj.parent_id === filterProperty )
	 }
	if (reqObj["type"] === "dropdown-tree-select") {
		let parentValues = reqObj.data.filter(
			(obj) => parseInt(obj.parent_type_id) === parseInt(filterProperty) && (obj.parent_id === "" || obj.parent_id === null),
		);
		newData = parentValues.filter((obj) => {
			const nestedValues = reqObj.data.filter(
				(nestedObj) => {
					return nestedObj.parent_type_id == parseInt(filterProperty) &&  nestedObj.parent_id === obj.id
				},
			);
			obj["label"] = obj.name;
			obj["value"] = obj.name;
			obj["children"] = nestedValues.filter((filterObj) => {
				filterObj["label"] = filterObj.name;
				filterObj["value"] = filterObj.name;
				return filterObj;
			});
			// console.log("Nested Values ",nestedValues,obj,obj.id)
			return obj;
		});
		newData = newData.filter((parent) => {
			parent["children"].forEach((nestedObj) => {
				const nestedValues = reqObj.data.filter(
					(nestedValueObj) => nestedValueObj.parent_id === nestedObj.id,
				);
				nestedObj["label"] = nestedObj.name;
				nestedObj["value"] = nestedObj.name;
				nestedObj["children"] = nestedValues.filter((filterObj) => {
					filterObj["label"] = filterObj.name;
					filterObj["value"] = filterObj.name;
					return filterObj;
				});
			});
			return parent;
		});

		// 
		newData = newData.filter((parent) => {
			parent["children"].forEach((nestedObj) => {
				nestedObj["children"].forEach((nestedNestedObj) => {
					const nestedValues = reqObj.data.filter(
						(nestedValueObj) => nestedValueObj.parent_id === nestedNestedObj.id,
					);
					nestedNestedObj["label"] = nestedNestedObj.name;
					nestedNestedObj["value"] = nestedNestedObj.name;
					nestedNestedObj["children"] = nestedValues.filter((filterObj) => {
						filterObj["label"] = filterObj.name;
						filterObj["value"] = filterObj.name;
						return filterObj;
					});
				});
				return nestedObj;
			});
			return parent;
		});

	} else {
		newData.filter((obj) => {
			obj["label"] = obj.name;
			obj["value"] = obj.name;
		});
	}
	return newData;
};

export const getData = (modelName) => {
	return postApiCall(`get_data_model?model_name=${modelName}`,{model_name: modelName})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};
