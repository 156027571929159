import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { makeStyles, styled } from "@mui/styles";

import "./DatePicker.scss";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { Paper } from "@mui/material";

const styles = (theme) => ({
  tab: {
    border: "4px solid #dfeaf5",
  },
});

const useStyles = makeStyles(styles);

const Item = styled(Paper)(({ theme, width, labeldirection }) => ({
  ...theme.typography.body2,
  display: labeldirection === "row" && "flex",
  alignItems: labeldirection === "row" && "center",
  justifyContent: labeldirection === "row" && "space-between",
  textAlign: "left",
  boxShadow: "none",
  background: "transparent",
  // width,
  color: theme.palette.text.secondary,
}));

const CustomDatePicker = (props) => {
  const [value, setDate] = useState(
    (props?.data?.defaultDate && new Date(props?.data?.defaultDate)) || null
  );
  const {
    label,
    format,
    fieldWidth,
    labelStyle,
    isMandatory,
    width,
    labeldirection,
    defaultDateOffset,
    disabled = false,
  } = props.data;
  const handleDateChange = (newDate) => {
    setDate(new Date(newDate));
  };
  const getDefaultDate = (offset) => {
    let currentDate = new Date();
    if (offset) {
      switch (offset.type) {
        case "months":
          {
            currentDate.setMonth(currentDate.getMonth() + offset.value);
          }
          break;
        default: {
          currentDate.setDate(currentDate.getDate() + offset.value);
        }
      }
    }
    return currentDate;
  };
  return (
    <div>
      <Item
        className="filterGroup"
        labeldirection={labeldirection}
        width={width}
      >
        <label
          style={{
            marginRight: labeldirection === "row" && 16,
            paddingBottom: labeldirection === "row" && 0,
            fontWeight: 500,
            ...labelStyle,
          }}
        >
          {label}
          {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
        </label>
        <div style={{ width: fieldWidth }}>
          <DatePicker
            onChange={(newDate) => handleDateChange(newDate)}
            value={value || getDefaultDate(defaultDateOffset)}
            clearIcon={null}
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
            format={format || "dd/MM/yyyy"}
            calendarIcon={
              <CalendarMonthOutlined
                style={{ fontSize: 20, color: "#515151" }}
              />
            }
            disabled={disabled}
            disableCalendar={disabled}
          />
        </div>
      </Item>
    </div>
  );
};

export default CustomDatePicker;
