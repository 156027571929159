import React from "react";

const CellColor = (params) => {
  const { value, decider, values } = params;

  const colorCalc = () => {
    var color = "white";

    if (decider === "range") {
      color = values.find(
        ({ minValue, maxValue }) => value >= minValue && value <= maxValue
      )?.color;
    } else if (decider === "equator") {
      color = values.find(({ element }) => value === element)?.color;
    }
    return color;
  };

  const cellStyle = {
    backgroundColor: colorCalc(),
    textAlign: "center",
  };

  const tableStyle = {
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  };

  return (
    <table style={tableStyle}>
      <tbody>
        <tr>
          <td style={cellStyle}>{params.value}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default CellColor;
