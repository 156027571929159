const StepAction = ({ onClick, value }) => {
  const btnText = value ? "\u2714" : "Mark as done";
  return (
    <button
      style={{ maxWidth: "max-content", marginLeft: "30px" }}
      className={value ? "msg-action-button-marked" : "msg-action-button"}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};
export default function Steps({ item, handleClick }) {
  return (
    <div>
      <p className="msg-text mg-t">{item?.message?.response_heading}</p>
      <div className="card-wrapper">
        {item?.message?.steps?.map((cardItem, index) => (
          <div className="step-container">
            <div
              className="step-text"
              dangerouslySetInnerHTML={{ __html: cardItem.text }}
            />
            <StepAction
              onClick={() => handleClick(index)}
              value={cardItem.complete}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
