import { Button } from "@mui/material";
const container = {
  width: "250px",
};
const header = {
  borderBottom: "1px solid #d3d3d3",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
};

export default function () {
  return (
    <div style={container}>
      <div style={header}>
        <Button
          variant="contained"
          sx={{ textTransform: "none", boxShadow: "none" }}
        >
          Save Table Config
        </Button>
      </div>
    </div>
  );
}
