import React from "react";
import { Grid } from "@mui/material";
import FilterSection from "../FilterSection";
import Chart from "../../../components/Charts/Chart";
const VerticleCharts = (props) => {
  const { charts } = props;
  return (
    <>
      {charts.map((obj) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {obj.filters && <FilterSection filters={obj.filters} />}
          <Chart
                chartType={obj.type}
                height={obj.height}
                title={obj.title}
                yaxis={obj.yaxis}
                xaxis={obj.xaxis}
                showLegend={obj.showLegend}
                stacking={obj.plotOptions?.series?.stacking}
                data={obj.data}
			/>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
export default VerticleCharts;