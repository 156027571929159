import Checkbox from "@mui/material/Checkbox";
import { useCallback, useMemo } from "react";

export default function CellCheckbox(params) {
  const rowGroup = useMemo(() => {
    return new Set(params.data["#rowGroup"]?.split(","));
  }, []);

  const onChange = useCallback(
    (e, params) => {
      params.api.forEachNode((node) => {
        if (rowGroup.has(node.data.id)) {
          node.setSelected(e.target.checked);
        }
      });
    },
    [rowGroup]
  );
  return <Checkbox onChange={(e) => onChange(e, params)} />;
}
