import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { Grid } from "@mui/material";

function CellMultipleSelect(props) {
  const [options, setOptions] = useState([]);
  const [isMulti, setIsMulti] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [dropdownObj, setDropdownObj] = useState({});
  const dispatch = useDispatch();
  const { tableInfo } = useSelector((state) => state.home);
  const { formMasterValuesData, formMenuData } = useSelector(
    (state) => state.filter
  );

  useEffect(() => {
    let params = {...dropdownObj}
    props.dropdownList.forEach(dropdown=>{
      const optionsList = formMasterValuesData.filter((obj) => {
        if (parseInt(obj.parent_id) === dropdown.filter_id) {
          obj.label = obj.name;
          obj.value = obj.name;
          return obj;
        }
      });
      const formDetails = formMenuData.filter( (obj) => parseInt(obj["categoryid"]) === dropdown.filter_id )[0] || {};
      params[`${dropdown.name}`] ={
        isMulti: formDetails.type === "multi-select",
        options: optionsList,
        selectedValues: props.value ? optionsList.filter( (obj) => props.data[`${dropdown.key}`].split(",").indexOf(obj.value) !== -1) : []
      }
    })
    setDropdownObj({...params})
    
  }, []);

  const updateSelected = (params,dropdownName) => {
    let updateParams = {...dropdownObj}
    updateParams[`${dropdownName}`][`selectedValues`] = params
    setDropdownObj({...updateParams})
  };

  return (
    <div>
      {props.value && Object.keys(dropdownObj).length > 0 ? (
        <>
        <Grid container spacing={2}>
          { props.dropdownList.map(obj=>(
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectRenderer
                  options={dropdownObj[`${obj.name}`] && dropdownObj[`${obj.name}`].options}
                  selectedItems={dropdownObj[`${obj.name}`] && dropdownObj[`${obj.name}`].selectedValues}
                  updateSelected={(params)=>{
                    updateSelected(params,obj.name)
                  }}
                  isMulti={dropdownObj[`${obj.name}`] && dropdownObj[`${obj.name}`].isMulti}
                  width={obj.width}
                />
              </Grid>
          ))}
        </Grid>
        </>
      ): null }
    </div>
  );
}

export default CellMultipleSelect;
