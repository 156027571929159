import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const defaultAnchorOrigin = { vertical: 'bottom', horizontal: 'left'}
const SnackBar = (props) => {
	const { message, variant, handleClose, anchorOrigin, width } = props;

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	return (
		<Snackbar
			className={`MuiSnackbar-root ${anchorOrigin?'':'MuiSnackbar-anchorOriginTopRight'} customSnackbarStyle`}
			open={true}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={anchorOrigin||defaultAnchorOrigin}>
			<Alert
				onClose={handleClose}
				severity={variant}
				sx={{ width: width || "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default SnackBar;
