import React, { useState } from "react";
// import CustomActionButton from "./CustomActionButton";
import CellCustomCheckbox from "./CellCustomCheckbox";
import { CustomRendererComponent } from "../../../pages/Home/CustomComponents/CustomRendererComponent";

function getComponent(props, compType) {
	let targetCellColumnId = null;
	if (
		compType.split(":").length &&
		compType.split(":")[0].trim() === "CopyTableCellData"
	) {
		targetCellColumnId = compType.split(":")[1].trim();
		compType = "CopyTableCellData";
	}

	if (
		compType.split(":").length &&
		compType.split(":")[0].trim() === "PasteCopiedTableCellData"
	) {
		targetCellColumnId = compType.split(":")[1].trim();
		compType = "PasteCopiedTableCellData";
	}
	return (
		<CustomRendererComponent
			props={props}
			type={"actions-list"}
			icon={compType}
			targetCellColumnId={targetCellColumnId}
			style={{
				paddingTop: "0px",
			}}
			options={[
				{
					...props.modalProp,
				},
			]}
			index={0}
			parent_table_key={props.parent_table_key}
		/>
	);
}

function getActionValueCellComponent(props, compType) {
	if (compType === "checkbox") {
		return <CellCustomCheckbox {...props} />;
	}
}
function CustomMultiComponentCell(props) {
	const { data, value, type, dependentOnColumn, column } = props;
	switch (type) {
		case "action-list":
			if (value?.length) {
				let compList = value.split(",");
				return (
					<div style={{ display: "flex" }}>
						{compList.map((component, index) => {
							let compType = component.trim();
							return getComponent(props, compType);
						})}
					</div>
				);
			} else {
				return <div>{value}</div>;
			}
		case "action-value-cell":
			let { colId } = column;
			let compColumnName = `${colId}#component`;
			let cellComponents = data?.[compColumnName];
			let cellCompList =
				cellComponents && cellComponents.length && cellComponents.split(",");
			// currently only for single component
			let comp = cellCompList && cellCompList.length && cellCompList[0];

			if (comp && comp.length) {
				if (comp.split("-")[1] === "left") {
					return (
						<div style={{ display: "flex" }}>
							{getActionValueCellComponent(props, comp.split("-")[0])}
							{value}
						</div>
					);
				}
				if (comp.split("-")[1] === "right") {
					return (
						<div style={{ display: "flex" }}>
							{value}
							{getActionValueCellComponent(props, comp.split("-")[0])}
						</div>
					);
				}
			}

			return <div>{value}</div>;

		default:
			return <div>{value}</div>;
	}
}

export default CustomMultiComponentCell;
