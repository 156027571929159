import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { getMultipleModelsData } from "../action";
import DownloadIcon from '@mui/icons-material/Download';
const  DownloadFiles = (props) => {
    const [pres, setPres] = useState([]);
    const workbook = XLSX.utils.book_new();


  const exportFile = async() => {
    const wb = XLSX.utils.book_new();
    const dowbloadObj = await getMultipleModelsData({model_names: props.apiKeys});
    props.data.forEach(obj=>{
        const ws = XLSX.utils.json_to_sheet(dowbloadObj[`${obj.key}`]);
        XLSX.utils.book_append_sheet(wb, ws, obj.name);
    })
    
    XLSX.writeFileXLSX(wb, `${props.fileName}.xlsx`);
  }


  return (
    <>
                      <Button
                        style={{ textTransform: "none", margin: 5 }}
                        variant={(props?.varient || "contained")}
                        size={(props?.size || "medium")}
                        title={props?.name}
                        onClick={exportFile}
                        startIcon={( props.name ? null : <DownloadIcon /> )}
                        >
                        { props.name }
                      </Button>
    </>
  )
}

export default DownloadFiles;