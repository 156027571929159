import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { CustomModal } from "./customModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { addNotifications } from "../data";
import SnackBar from "../../../components/SnackBar";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DownloadIcon from "@mui/icons-material/Download";

export default function MenuPopupState(props) {
	const { data } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(null);
	const [showMessage, setShowMessage] = useState(false);
	const [itemIndex, setItemIndex] = useState(null);
	const [label, setLabel] = useState(data?.label);
	const { apiData, dependentInfo, tableInfo, selectedRowInfo, gridRefs } =
		useSelector((state) => state.home);
	const onAction = (action, index, popupState) => {
		if (action.actionName === "navigate") {
			if (action.otherActionName === "notifications") {
				addNotifications(action);
			}
			navigate(
				"/" +
					window?.location?.pathname?.split("/")[1] +
					"/" +
					window?.location?.pathname?.split("/")[2] +
					action.path
			);
		} else if (action.actionName === "modal") {
			setOpen(!open);
			setItemIndex(index);
		} else if (action.actionName === "click") {
			let params = {};
			params[`${action.key}`] = dependentInfo[`${action.key}`]
				? !dependentInfo[`${action.key}`]
				: true;
			if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
				params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
					? !dependentInfo[`${action.otherKey}`]
					: true;
			} else {
				params[`${action.otherKey}`] = true;
			}
			if (action["defaultToggle"]) {
				params[`${action.key}`] = action.defaultToggle;
			}
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "DROPDOWN_DINNERWARE_ACTION") {
			let params = {};
			params[`plate-store-cluster`] = false;
			params[`dinnerware-store-cluster`] = true;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "ENABLE_DISABLE_KEYS") {
			let params = {};
			params[action.key] = true;
			params[action.otherKey] = false;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "DROPDOWN_PLATE_ACTION") {
			let params = {};
			params[`dinnerware-store-cluster`] = false;
			params[`plate-store-cluster`] = true;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "ENABLE_DISABLE_KEYS") {
			let params = {};
			params[action.key] = true;
			params[action.otherKey] = false;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "displayMessage") {
			setShowMessage(true);
			setMessage(action.message);
		} else if (action.actionName === "COPY_COLUMN_DATA") {
			const selectedRows = gridRefs[action.parent_table_key]?.current.api
				.getSelectedNodes()
				.map((node) => node.rowIndex);
			if (selectedRows && selectedRows.length > 0) {
				const rows = [...tableInfo[action.parent_table_key]];
				selectedRows.forEach(
					(rowIndex) =>
						(rows[rowIndex][action.dest_field] =
							rows[rowIndex][action.source_field])
				);
				dispatch({
					type: "TABLE_DATA",
					payload: { [action.parent_table_key]: [...rows] },
				});
			}
		}
		popupState.close();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMessageClose = () => {
		setShowMessage(false);
		setMessage(null);
	};

	const renderButtonWithIcon = (popupState, icon) => {
		return (
			<Button
				style={{
					textTransform: "none",
				}}
				variant={data.variant || "contained"}
				{...bindTrigger(popupState)}
				startIcon={icon}
				sx={{
					"& .MuiButton-startIcon": { margin: "0px" },
					...data?.style,
				}}>
				{data?.label}
			</Button>
		);
	};

	const IconSelector = (props, popupState) => {
		let icon;

		switch (props.iconType) {
			case "edit":
				icon = <EditIcon />;
				return renderButtonWithIcon(popupState, icon);
			case "download":
				icon = <DownloadIcon />;
				return renderButtonWithIcon(popupState, icon);

			case "time":
				icon = <AccessTimeIcon />;
				return renderButtonWithIcon(popupState, icon);

			default:
				return (
					<Button
						style={{ textTransform: "none", margin: 5, width: data.width }}
						variant={data.variant || "contained"}
						{...bindTrigger(popupState)}>
						{label}
					</Button>
				);
		}
	};
	const renderPopupVIew = () => {
		return (
			<>
				{showMessage && (
					<SnackBar
						message={message}
						variant="success"
						handleClose={handleMessageClose}
					/>
				)}
				<PopupState variant="popover" popupId="demo-popup-menu">
					{(popupState) => (
						<React.Fragment>
							{data.iconType ? (
								IconSelector(data, popupState)
							) : (
								<Button
									style={{
										textTransform: "none",
										width: data.width,
										...data?.style
									}}
									variant={data.variant || "contained"}
									{...bindTrigger(popupState)}>
									{label}
								</Button>
							)}
							<Menu {...bindMenu(popupState)}>
								{data.options.map((option, index) => (
									<>
										{option.dependentOn ? (
											<>
												{" "}
												{dependentInfo[`${option.dependentOn}`] ? null : (
													<>
														<MenuItem
															onClick={() => onAction(option.action, index)}>
															{option.name}
														</MenuItem>
													</>
												)}{" "}
											</>
										) : (
											<>
												<MenuItem
													onClick={() => {
														updateLabel(option.name);
														if (Array.isArray(option.action)) {
															option.action.forEach((action, index) =>
																onAction(action, index, popupState)
															);
														} else onAction(option.action, index, popupState);
													}}>
													{option.name}
												</MenuItem>
											</>
										)}{" "}
									</>
								))}
							</Menu>
						</React.Fragment>
					)}
				</PopupState>
			</>
		);
	};

	const updateLabel = (name) => {
		if (data?.dynamicLabel) {
			setLabel(name);
		}
	};
	return (
		<>
			{open ? (
				<CustomModal
					show={open}
					{...data.options[itemIndex]["action"]}
					handleClose={handleClose}
				/>
			) : null}
			{props["isDivNotRequired"] ? (
				<>{renderPopupVIew()}</>
			) : (
				<div style={{ textAlign: data && data.alignment }}>
					{renderPopupVIew()}
				</div>
			)}
		</>
	);
}
