import React from "react";
import ButtonGroup from "../../MUIButtonGroup";
import styles from "../index.module.css";

// eslint-disable-next-line react/prop-types
export default function CalendarViewToggle({
	calTypToggleVal,
	onToggleChange,
	toggleOptions,
}) {
	return (
		<div className={`${styles["toggle-container"]}`}>
			<ButtonGroup
				options={toggleOptions}
				onChange={onToggleChange}
				value={calTypToggleVal}
			/>
			
		</div>
	);
}
