import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
const CellAddIcon = (props) => {
	const dispatch = useDispatch();
	const {
		selectedRowInfo,
		tableInfo,
		editActionInfo,
		dependentInfo,
		formEditedValues,
	} = useSelector((state) => state.home);

	const getIcon = () => {
		switch (props.iconType) {
			case "WarningIcon":
				return (
					<Tooltip title={`${props.data["#tooltip_text"]}`}>
						<WarningIcon
							sx={{
								color: "#D8000C",
								height: "20px",
								width: "20px",
								verticalAlign: "middle",
								margin: "0px 10px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				);
				break;

			default:
				break;
		}
	};
	const getAddIconFlag = () => {
		const { column: { colId = null } = {}, data = {} } = props;
		if (props?.data?.[`#add_icon_${colId}`] === "Y") {
			return true;
		} else {
			return false;
		}
	};
	return (
		<div>
			{getAddIconFlag() ? (
				<div>
					{props.value ? (
						props.position === "start" ? (
							<>
								{getIcon()} {props.value}
							</>
						) : (
							<>
								{props.value} {getIcon()}
							</>
						)
					) : null}
				</div>
			) : (
				<div>{props.value}</div>
			)}
		</div>
	);
};

export default CellAddIcon;
