import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Chip } from "@mui/material";
import Text from "../../utils/Text";

const useStyles = makeStyles({
  summaryContainer: {
    border: `1px solid lightgray`,
    padding: `0.5rem`,
    borderRadius: 10,
    fontSize: "12px",
    margin: `1rem 0`,
    backgroundColor: "white",
  },
  summaryChip: {
    margin: "0 0.1rem",
  },
  dimension: {
    margin: "0px 20px 0px 10px",
  },
});
const InfoPanel = (props) => {
  const classes = useStyles();
  const {data} = props;
  const getChips = (dimension) => {
    if (dimension.label) {
      return (
        <>
          {
            (data.uniqueKey === "grocery-price" ? (
              <Box
                sx={
                  dimension?.wrapperStyle
                    ? dimension.wrapperStyle
                    : {
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",
                        marginRight: 1,
                      }
                }
              >
                <Text
                  style={
                    dimension?.labelStyle
                      ? dimension.labelStyle
                      : {
                          color: "#758498",
                          fontSize: "12px",
                          marginRight: 5,
                        }
                  }
                >
                  {dimension?.label}
                </Text>
                {dimension?.section?.split("|")?.map((item) => (
                  <Chip
                    className={classes.summaryChip}
                    sx={
                      dimension?.chipStyle
                        ? dimension.chipStyle
                        : {
                            fontSize: "12px",
                            color: "#758490",
                            background: "#F7F7F7",
                          }
                    }
                    label={item}
                  />
                ))}
              </Box>
            ) : (
              <span className={classes.dimension}>
                {dimension.label}
                {dimension?.section?.split(",")?.map((item) => (
                  <Chip className={classes.summaryChip} label={item} />
                ))}
              </span>
            ))
          }
        </>
      );
    } else
      return <span style={{marginRight:"20px"}}>
      {dimension?.section?.split(",")?.map((item) => (
        <Chip className={classes.summaryChip} label={item} />
      ))}
    </span>
  };

  return (
    <>
      {
        (data.uniqueKey === "grocery-price" ? (
          <Box
            style={
              data?.infoPanelStyle
                ? data.infoPanelStyle
                : {
                    display: "flex",
                    gap: 5,
                    border: "1px solid #C8CED7",
                    borderRadius: "3px",
                    padding: `0.5rem`,
                    backgroundColor: "white",
                  }
            }
          >
            {data.info.map((dimension) => getChips(dimension))}
          </Box>
        ) : (
          <div className={classes.summaryContainer}>
            {data.info.map((dimension) => getChips(dimension))}
          </div>
        ))
      }
    </>
  );
};

export default InfoPanel;
