import { useMemo } from "react";
import CellGroupSet from "./CellGroupSet";
import CellDropdownMulti from "./CellDropdownMulti";
import CellDropdownMultiV2 from "./CellDropdownMultiV2";
import CellDropdownMap from "./CellDropdownMap";
import CellDropdownMapV2 from "./CellDropdownMapV2";
import CellTextField from "./CellTextField";
import CellCompute from "./CellCompute";
import Button from "@mui/material/Button";
import CellChips from "./CellChips";
import CellDateRange from "./CellDateRange";
export default function CellRendererSelector(params) {
	const parent_table_List = [
    "tb_finalized_pricing_intersection_absolute",
    "tb_finalized_pricing_intersection_absolute_BE",
    "tb_finalized_pricing_intersection_perc_diff_ly",
    "tb_finalized_pricing_intersection_perc_diff_ly_BE",
    "tb_finalized_collection_total_absolute",
    "tb_finalized_collection_total_absolute_BE",
    "tb_finalized_collection_total_perc_diff_ly",
    "tb_finalized_collection_total_perc_diff_ly_BE",
    "tb_finalized_department_absolute",
    "tb_finalized_department_absolute_BE",
    "tb_finalized_department_perc_diff_ly",
    "tb_finalized_department_perc_diff_ly_BE",
    "tb_draft_pricing_intersection_absolute",
    "tb_draft_pricing_intersection_absolute_BE",
    "tb_draft_pricing_intersection_perc_diff_ly",
    "tb_draft_pricing_intersection_perc_diff_ly_BE",
    "tb_draft_collection_total_absolute",
    "tb_draft_collection_total_absolute_BE",
    "tb_draft_collection_total_perc_diff_ly",
    "tb_draft_collection_total_perc_diff_ly_BE",
    "tb_draft_department_absolute",
    "tb_draft_department_absolute_BE",
    "tb_draft_department_perc_diff_ly",
    "tb_draft_department_perc_diff_ly_BE",
    "tb_constraints_wos_factor_rule",
    "tb_total_su_ct_qr_editable_view_regular",
    "tb_total_su_ct_qr_editable_view_regular_af",
    "tb_total_su_ct_qr_editable_view_regular_override",
    "tb_total_su_ct_qr_editable_view_clearance",
    "tb_total_su_ct_qr_editable_view_clearance_af",
    "tb_total_su_ct_qr_editable_view_clearance_override",
    "tb_constraints_eol_rules",
    "tb_constraints_wos_factor_rule",
    "tb_constraints_sneak_peek",
    "tb_constraints_epoc_freezing",
  ];

	const getComponent = (compDetail) => {
		let compDetailList = compDetail.split("-");

		// If case of simple value to be shown in cell
		if (compDetailList[0].trim() !== "component") {
			return compDetailList[0] || "";
		}

		// Get component name
		let compName =
			compDetailList.length && compDetailList[1] && compDetailList[1].trim();

		switch (compName) {
			case "inputfield":
				let textFieldValue = compDetailList[2];
				return (
					<div style={{ marginTop: "10px" }}>
						<CellTextField value={textFieldValue} />
					</div>
				);
			case "button":
				let buttonLabel = compDetailList[2];
				return <Button variant="text">{buttonLabel}</Button>;
			case "dropdown":
				let dropdownValue = compDetailList[2];
				return <CellDropdownMultiV2 {...params} value={dropdownValue} />;
			case "dropdownGroup":
				let doropDownCount = compDetailList[2].split(",").length || 1;
				let dropDownValueSet = compDetailList[2];
				return (
					<CellDropdownMapV2
						{...params}
						dropdownsCount={doropDownCount}
						value={dropDownValueSet}></CellDropdownMapV2>
				);
			default:
				return "";
		}
	};

	const renderer = useMemo(() => {
		if (parent_table_List.includes(params?.parent_table_key)) {
			// component-inputfield-null#component-button-25% Off
			const { value = "" } = params;
			let compList = value && value !== "" ? value.split("#") : [];

			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{compList.map((comp, index) => {
						let compDetail = comp.trim();
						return getComponent(compDetail);
					})}
				</div>
			);
		} else {
			const type =
				params?.data && (params.data["#renderer_type"] || params?.defaultType);
			switch (type) {
				case "groupSet":
					return <CellGroupSet {...params} />;
				case "dropdown":
					return <CellDropdownMulti {...params} />;
				case "cellEdit":
					return <CellTextField {...params} />;
				case "cellCompute":
					return <CellCompute {...params} />;
				case "colorChips":
					return <CellChips {...params} />
				case "dateRange":
					return <CellDateRange{...params}/>;
				default:
					return params.value || "";
			}
		}
	}, [params]);
	return renderer;
}
