const List = ({ data }) => {
  return (
    <>
      {data?.listType === "ordered" ? (
        <ol style={{ ...data?.wrapperStyle }}>
          {data.data.map((item) => (
            <li style={{ fontSize: 14, ...item?.style }}>{item.value}</li>
          ))}
        </ol>
      ) : (
        <ul style={{ ...data?.wrapperStyle }}>
          {data.data.map((item) => (
            <li style={{ fontSize: 14, ...item?.style }}>{item.value}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default List;