import React, { useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { CustomRendererComponent } from "./CustomRendererComponent";
import { Table } from "../../../components/Table";

const CustomTable = (props) => {
  const { formMasterValuesData } = useSelector((state) => state.filter);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 200,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: "type",
      headerName: "Type",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: function (params) {
        return {
          values: ["Department", "Class", "SubClass", "Category"],
        };
      },
      width: 300,
    },
    {
      field: "placeholder",
      headerName: "Placeholder",
      cellRenderer: "CustomRenderComponent",
      cellRendererParams: {
        mappingKey: "name",
        parent_table_key: "tb_manage_placeholders",
        type: "textfield",
        default_open: true,
        action: {
          type: "ADD_TO_TABLE",
        },
      },
    },
    {
      field: "department",
      headerName: "Department",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: function (params) {
        return {
          values: formMasterValuesData
            .filter((obj) => obj.parent_type_id == 21 && obj.parent_id == 21)
            .map((obj) => obj.name),
        };
      },
      width: 300,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  return (
    <Table
      apikey={"tb_manage_placeholders"}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      frameworkComponents={{
        CustomRenderComponent: CustomRendererComponent,
      }}
      tableCssStyle={
        props.tableCssStyle === undefined ? "default" : props.tableCssStyle
      }
      isDropdownEditRequired={true}
    />
  );
};

export default CustomTable;
