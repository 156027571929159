export const WaterfallChartOption = (props) => {
    return {
        chart: {
            type: "waterfall"
        },
        title: {
            text: props.title
        },
        xAxis: {
            title: {
                text: props.xaxis.title
            },
			categories: props.xaxis.categories,
        },
        yAxis: {
            title: {
                text: props.yaxis.title
            }
        },
        legend:{
            enabled: props?.showLegend
        },
        tooltip: {
            enabled: false
        },
        series: props.data,
        credits: {
			enabled: false,
		}
    }
}