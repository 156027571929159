import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CustomButton from "../../../components/CustomButton";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import ComponentResolver from "../Resolver/ComponentResolver";
import {
	Grid
} from "@mui/material";
const styles = (theme)=>({
        list: {
          width: 250,
        },
        fullList: {
          width: 'auto',
        },
        filtersHeader: {
          ...theme.content.flexStyles.flexRow,
          ...theme.content.flexStyles.flexAlignStartCenter,
          float: "right",
          text: "right",
        },
        filtersHeaderTitle: {
          ...theme.typography.fontSizes.sectionHeaderText,
          margin: "0",
          marginRight: 48,
          fontWeight: theme.typography.fontWeight.bold,
        }
})
const useStyles = makeStyles();

export default function FilterDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false
  });
  const {
		dependentInfo
	} = useSelector((state) => state.home);

  useEffect(() => {
    ['top'].map((anchor) => (
      setState({ ...state, [anchor]: false })
    ))
  }, [dependentInfo]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <CustomButton
                        isPrimary={true}
                        variant="outlined"
                        height={32}
                        width={154}
                        label="Close Filter"
                        startIcon={<FilterAltOutlined />}
                        onButtonClick={toggleDrawer(anchor, false)}
                    />
            </Grid>
      
      </Grid>
      <ComponentResolver pageObject={{ components: props?.data?.components }} />
    </div>
  );

  return (
    <div>
        {['top'].map((anchor) => (
            <React.Fragment key={anchor}>
                <div className={classes.filtersHeader} style={{...props?.style}}>
                    <CustomButton
                        isPrimary={true}
                        variant="contained"
                        height={32}
                        width={154}
                        label="Select Filters"
                        startIcon={<FilterAltOutlined />}
                        onButtonClick={toggleDrawer(anchor, true)}
                    />
                </div>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
                </Drawer>
            </React.Fragment>
            ))}
    </div>
  );
}
