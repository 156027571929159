
import { Tree, TreeNode } from "react-organizational-chart";
import "./Treechart.css";
import { useState } from "react";
import ComponentResolver from "../../../pages/Home/Resolver/ComponentResolver";
import BungalowIcon from '@mui/icons-material/Bungalow';
import { CustomModal } from "../../../pages/Home/CustomComponents/customModal";

const Treechart = ({ charts }) => {
	const [previewConfig, setPreviewConfig] = useState("");
	const [open, setOpenAssort] = useState(false);

	const setModal = (param) => {
		setPreviewConfig(param)
		if (param.type && param.type === "modal") {
			setOpenAssort(!open);
			
		}
		
	}

	const handleClose = () => {
		setOpenAssort(false);
		
	  };

    const Nodes = ({ charts }) => {
		return (
			<>
				{charts.children &&
					charts.children.map((doc) => {
						return (
							<TreeNode
								label={
									
									<button
										onClick={() => {
											doc.onclick
												? setModal(doc.onclick) :
												setPreviewConfig("")
											
										}}
										className="node_button"
										style={{
											backgroundColor: doc.backgroundColor,
											boxShadow:doc.boxshadow,
											overflowY:doc.overflow,
											border:doc.border || "2px solid black",
											height:doc.height,
											cursor: "pointer",
											padding:doc.padding || "15px",
											borderRadius: "5px",
											width: doc.width || "150px",
											position:"relative",
											bottom:"3px"
										}}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<div>{doc.sku_current && doc.sku_current}</div>
											<div
												style={{
													color: "red",
													marginLeft: "5px",
													marginRight: "5px",
													fontWeight: "600",
												}}
											>
												{doc.net_change && doc.net_change}
											</div>
											<div>{doc.sku_future && doc.sku_future}</div>
										</div>

										<div style={{ padding: "4px" }}>{doc.name}
										
										{doc.type && doc.item.map((list)=>{
											return(
                                             <div style={{display:"flex",color:"rgb(212 215 218)"}}>
											 <BungalowIcon sx={{ fontSize: 22 }} style={{position:"relative",bottom:"5px"}} />
											<div style={doc.itemStyle}> {list.name}</div>
											</div>
										
											)
										})}
										
										
										</div>

										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<div>{doc.sku_added && doc.sku_added}</div>
											<div>{doc.sku_deleted && doc.sku_deleted}</div>
										</div>
									</button>
								}
							>
								{doc.children && <Nodes charts={doc} />}
							</TreeNode>
						);
					})}
			</>
		);
	};

	return (
		<div className="cont">
			<Tree
				lineWidth={"3px"}
				lineColor={charts.lineColor || "black"}
				lineBorderRadius={"4px"}
				label={
					<button
						style={{
							backgroundColor: charts.backgroundColor,
							border: charts.border || "3px solid black",
							cursor: "pointer",
							padding: "15px",
							borderRadius: "6px",
							width: "200px",
						}}
					>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>{charts.sku_current && charts.sku_current}</div>
							<div
								style={{
									color: "red",
									marginLeft: "5px",
									marginRight: "5px",
									fontWeight: "600",
								}}
							>
								{charts.net_change && charts.net_change}
							</div>
							<div>{charts.sku_future && charts.sku_future}</div>
						</div>

						<div style={{ margin: "2px" }}> {charts.root}</div>

						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>{charts.sku_added && charts.sku_added}</div>
							<div>{charts.sku_deleted && charts.sku_deleted}</div>
						</div>
					</button>
				}
			>
				<Nodes charts={charts} />
			</Tree>
			{/* {previewConfig.hasOwnProperty("type") && previewConfig.type === "modal" ? ( */}
			
        <CustomModal
          show={open}
          {...previewConfig.action}
          handleClose={handleClose}
		  setOpenAssort={setOpenAssort}
        />
     
			
			<ComponentResolver pageObject={previewConfig} redirectFrom="Home" />
			
		</div>
	);
};
export default Treechart;
