import React from "react";
import { eventStatusColors } from "../constants";

export default function CalendarEventsColorInfo({eventInfo}) {
  var defaultLabel = eventStatusColors;
  if (eventInfo && eventInfo.key === "tradesmart") {
    defaultLabel = eventInfo.labels;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Object.keys(defaultLabel).map((status) => (
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "32px" }}
        >
          <div
            style={{
              height: "15px",
              width: "15px",
              borderRadius:"100px",
              marginRight: "8px",
              backgroundColor: defaultLabel[status].border,
            }}
          />
          <div>{defaultLabel[status].label}</div>
        </div>
      ))}
    </div>
  );
}
