import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Text from "../../../utils/Text"

const marks = [
  {
    value: 0,
    label:"0"
  },
  {
    value: 20,
    label:"20"
  },
  {
    value: 80,
    label:"80"
  },
  {
    value: 100,
    label:"100"
  },
];

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#758498',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '2px solid #0055AF',
    '&:hover': {
    }
  },
  '& .MuiSlider-track': {
    height: 7
  },
  '& .MuiSlider-rail': {
    color: '#F0F0F0',
    opacity: 9,
    height: 9,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};
function valuetext(value) {
    return `${value}`;
  }

export default function CustomSlider(props) {
    const {data}=props
  return (
    <>
      {data.key === "generic" ? (
        <Box style={{ width: 320, ...data.wrapperStyle}}>
          <Text style={{marginBottom : 5, ...data?.labelStyle}}>{data.label}</Text>
          <Slider
            defaultValue={data.defaultValue || 0} // pass in a single digit for a single thumb and pass in an array of numbers for double thumbs
            valueLabelDisplay="auto"
            marks={data.marks || null} // these are labels to be displayed on the slider sample present above
            min={data.minValue || 0}
            max={data.maxValue || 100}
          />
        </Box>
      ) : (
    <Box sx={{ width: 320 }}>
      <Text style={data.labelStyle} >{data.label}</Text>
      <AirbnbSlider
        style={data.progressSliderStyle}
        slots={{ thumb: AirbnbThumbComponent }}
        getAriaValueText={valuetext}
        marks={marks}
        defaultValue={[20, 80]}
      />
    </Box>
      )}
    </>
  );
}
