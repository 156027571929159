import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import { Button, Grid } from "@mui/material";

const SelectedRowText = (props) => {
  const {
    parent_table_key,
    mappingKey,
    label,
    width,
    field_name,
    isStoreRequired,
  } = props.data;
  const dispatch = useDispatch();
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [editedValue, setEditedValue] = useState(null);
  const { selectedRowInfo, tableInfo } = useSelector((state) => state.home);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedValue(value);
  };

  const updateSelectedRow = () => {
    const selectedRows = selectedRowInfo[`${parent_table_key}`];
    selectedRows.filter((obj, index) => {
      if (index === 0) {
        obj[`${mappingKey}`] = editedValue;
      }
    });

    const tableData = tableInfo[`${parent_table_key}`];
    tableData.filter((obj) => {
      if (obj.id == selectedRows[0]["id"]) {
        obj[`${mappingKey}`] = editedValue;
      }
    });

    let selectedRowsParams = {};
    let tableDataParams = {};
    selectedRowsParams[`${parent_table_key}`] = [...selectedRows];
    tableDataParams[`${parent_table_key}`] = [...tableData];

    dispatch({
      type: "SELECTED_ROWS",
      payload: selectedRowsParams,
    });

    dispatch({
      type: "TABLE_DATA",
      payload: tableDataParams,
    });
    setIsEditEnable(false);
  };

  return (
    <>
      {(selectedRowInfo[`${parent_table_key}`] &&
        selectedRowInfo[`${parent_table_key}`].length > 0) ? (
        <>
            {isEditEnable ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextFieldWithLabel
                      type={"text"}
                      label={label}
                      width={width}
                      defaultValue={
                        selectedRowInfo[`${parent_table_key}`][0][
                          `${mappingKey}`
                        ]
                      }
                      name={field_name}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Button
                      style={{
                        textTransform: "none",
                        // width: item.width,
                        backgroundColor: "#0055AF",
                        boxShadow: "none",
                      }}
                      variant={"contained"}
                      size={"sm"}
                      onClick={updateSelectedRow}
                    >
                      Update
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      style={{
                        textTransform: "none",
                        // width: item.width,
                        boxShadow: "none",
                      }}
                      variant={"outlined"}
                      size={"sm"}
                      onClick={() => {
                        setIsEditEnable(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <b>
                  {selectedRowInfo[`${parent_table_key}`][0][`${mappingKey}`]}
                </b>{" "}
                &nbsp;
                <EditIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsEditEnable(true);
                  }}
                />
              </>
            )}
        </>
      ) : null}
    </>
  );
};

export default SelectedRowText;
