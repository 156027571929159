import { orderBy, sortBy } from "lodash";

export const getEachColumnInfo = (columnsList, columProperties) => {
  const columns = [];
  columnsList.forEach((element) => {
    const {
      id,
      parent_id,
      screen_name,
      descritpion,
      table_name,
      headerName,
      field,
      rowGroup,
      checkboxSelection,
      headerCheckboxSelection,
      pinned,
      hide,
      child_columns,
      columnGroupShow,
      maxWidth,
      width,
      cellRenderer,
      cellColorCode,
      aggFunc,
      filter,
      suppressMenu,
      floatingFilter,
      cellTextColorCode
    } = element;
    if (!element?.remove_from_demo) {
      let insertedObj = {
        id,
        parent_id,
        screen_name,
        descritpion,
        table_name,
        headerName,
        field,
        // rowGroup,
        // checkboxSelection: checkboxSelection === "TRUE",
        // headerCheckboxSelection: headerCheckboxSelection === "TRUE",
        // hide,
        child_columns,
        // columnGroupShow,
        // maxWidth,
        // width,
        // cellRenderer,
        // cellColorCode,
        // aggFunc,
      };

      if (rowGroup) {
        insertedObj["rowGroup"] = rowGroup;
      }
      if (checkboxSelection) {
        insertedObj["checkboxSelection"] = checkboxSelection === "TRUE";
      }
      if (headerCheckboxSelection) {
        insertedObj["headerCheckboxSelection"] =
          headerCheckboxSelection === "TRUE";
      }

      if (hide) {
        insertedObj["hide"] = hide;
      }
      if (columnGroupShow) {
        insertedObj["columnGroupShow"] = columnGroupShow;
      }
      if (cellRenderer) {
        insertedObj["cellRenderer"] = cellRenderer;
      }
      if (cellColorCode) {
        insertedObj["cellColorCode"] = cellColorCode;
      }
      if (aggFunc) {
        insertedObj["aggFunc"] = aggFunc;
      }
      if (cellColorCode) {
        insertedObj["cellColorCode"] = cellColorCode;
      }
      if (filter) {
        insertedObj["filter"] = filter;
      }
      if (suppressMenu) {
        insertedObj["suppressMenu"] = suppressMenu === "TRUE";
      }
      if (floatingFilter) {
        insertedObj["floatingFilter"] = floatingFilter === "TRUE";
      }
      if (pinned) {
        insertedObj["pinned"] = pinned;
      }

      if (element?.cellRenderer) {
        insertedObj["cellRenderer"] =
          columProperties[element.field]["cellRenderer"];
        insertedObj["cellRendererParams"] =
          columProperties[element.field]["cellRendererParams"];
      }

      if (element?.cellColorCode) {
        insertedObj["cellColorCode"] = JSON.parse(element["cellColorCode"]);
      }
      
      // if (element?.cellTextColorCode) {
      //   insertedObj["cellTextColorCode"] = JSON.parse(element["cellTextColorCode"]);
      // }
      // if (element?.rowValue) {
      //   insertedObj["rowValue"] = JSON.parse(element["rowValue"]);
      // }
      
      // if (element?.rowMappingKey) {
      //   insertedObj["rowMappingKey"] = JSON.parse(element["rowMappingKey"]);
      // }
      

      if (element?.valueFormatter) {
        // var fun = new Function("params", `${element.valueFormatter}`);
        // insertedObj["valueFormatter"] = fun.bind(this)
        // insertedObj["valueFormatter"] = function(params){
        //   const allRows = params.api.rowModel.rootNode.allLeafChildren
        //   if(params.data && [91].indexOf(parseInt(params.data.id))!==-1){ 
        //     const firstRow = allRows.filter(obj=> parseInt(obj.data.id) === 11 )[0]["data"]
        //     const secondRow = allRows.filter(obj=> parseInt(obj.data.id) === 48 )[0]["data"]
        //     return ( parseInt(firstRow.spring_feb_fw1) / parseInt(secondRow.spring_feb_fw1) )
        //   }
        // }
      }

      if (element?.editable) {
        insertedObj["editable"] = element.editable === "TRUE"
      }


      columns.push(insertedObj);
    }
  });
  return columns;
};

export const getDynmaicColumns = (
  tableConfig,
  tableKey,
  columProperties,
  pageConfigData
) => {
  const columns = [];
  // const columnsList = tableConfig.filter(obj=> {
  //     return obj.table_name === tableKey
  //   })
  let columnsList = tableConfig[tableKey] || [];
  if (columnsList.length > 0) {
    // let orderByColumns = orderBy(columnsList, ['order_by'],['asc']);
    let orderByColumns = sortBy(columnsList, [
      function (o) {
        return parseInt(o.order_by);
      },
    ]).filter(
      (obj) =>
        parseInt(obj.screen_name.split("_").slice(-1)) ===
        parseInt(pageConfigData["json_id"])
    );
    orderByColumns.forEach((element) => {
      if (!element?.parent_id && !element?.remove_from_demo) {
        const {
          id,
          parent_id,
          screen_name,
          descritpion,
          table_name,
          headerName,
          field,
          rowGroup,
          checkboxSelection,
          headerCheckboxSelection,
          hide,
          pinned,
          child_columns,
          columnGroupShow,
          maxWidth,
          width,
          cellRenderer,
          cellColorCode,
          aggFunc,
          filter,
          suppressMenu,
          floatingFilter,
          cellTextColorCode
        } = element;

        let insertedObj = {
          id,
          parent_id,
          screen_name,
          descritpion,
          table_name,
          headerName,
          field,
          // rowGroup,
          // checkboxSelection: checkboxSelection === "TRUE",
          // headerCheckboxSelection: headerCheckboxSelection === "TRUE",
          // hide,
          child_columns,
          // columnGroupShow,
          // maxWidth,
          // width,
          // cellRenderer,
          // cellColorCode,
          // aggFunc,
        };

        if (rowGroup) {
          insertedObj["rowGroup"] = rowGroup;
        }
        if (checkboxSelection) {
          insertedObj["checkboxSelection"] = checkboxSelection === "TRUE";
        }
        if (headerCheckboxSelection) {
          insertedObj["headerCheckboxSelection"] =
            headerCheckboxSelection === "TRUE";
        }

        if (hide) {
          insertedObj["hide"] = hide;
        }
        if (columnGroupShow) {
          insertedObj["columnGroupShow"] = columnGroupShow;
        }
        if (cellRenderer) {
          insertedObj["cellRenderer"] = cellRenderer;
        }
        if (aggFunc) {
          insertedObj["aggFunc"] = aggFunc;
        }
        if (cellColorCode) {
          insertedObj["cellColorCode"] = cellColorCode;
        }


        if (filter) {
          insertedObj["filter"] = filter;
        }
        if (suppressMenu) {
          insertedObj["suppressMenu"] = suppressMenu === "TRUE";
        }
        if (floatingFilter) {
          insertedObj["floatingFilter"] = floatingFilter === "TRUE";
        }

        if (pinned) {
          insertedObj["pinned"] = pinned;
        }

        // inserting children columns
        if (element?.child_columns) {
          const childColumns = columnsList.filter((obj) => {
            return obj.table_name === tableKey && obj.parent_id === element.id;
          });
          insertedObj["children"] = getEachColumnInfo(
            childColumns,
            columProperties
          );
        }

        // cell rendering

        if (element?.cellRenderer) {
          insertedObj["cellRenderer"] =
            columProperties[element.field]["cellRenderer"];
          insertedObj["cellRendererParams"] =
            columProperties[element.field]["cellRendererParams"];
        }

        if (element?.cellColorCode) {
          insertedObj["cellColorCode"] = JSON.parse(element["cellColorCode"]);
        }


        // if (element?.cellTextColorCode) {
        //   insertedObj["cellTextColorCode"] = JSON.parse(element["cellTextColorCode"]);
        // }

        // if (element?.rowValue) {
        //   insertedObj["rowValue"] = JSON.parse(element["rowValue"]);
        // }
        
        // if (element?.rowMappingKey) {
        //   insertedObj["rowMappingKey"] = JSON.parse(element["rowMappingKey"]);
        // }

        if (element?.valueFormatter) {
          // var fun = new Function("params", `${element.valueFormatter}`);
          // insertedObj["valueFormatter"] = fun.bind(this)
        }

        if (element?.editable) {
          insertedObj["editable"] = element.editable === "TRUE"
        }

        columns.push(insertedObj);
      }
    });
  }

  return columns;
};
